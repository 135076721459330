import * as React from 'react';
import { supabase } from '../supabaseClient'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InputLabel from '@mui/material/InputLabel';
import { ReadRowsSupabase } from "./ReadRowsSupabase";
import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom'


const AddRequestedJobs = ({session }) => {
  const navigate = useNavigate()

  // const fillingLineDB = ReadRowsSupabase({'session':session,'table':'fillingLine'});
  // let fillingLineData = [];

  // if(fillingLineDB?.data){
  //   fillingLineDB.data.forEach((data, index) => {
  //     fillingLineData[data.id] = data.name;
  //   });
  // }

  const itemNumberUpc = ReadRowsSupabase({'session':session,'table':'upc'});
  let itemNumberUpcData = [];
  let modelNumberData = [];
  let modelNumberAutocomplete = [];

  if(itemNumberUpc?.data){
    itemNumberUpc.data.forEach((data, index) => {
      itemNumberUpcData[data.itemNumber] = data.fullUPC;
      // modelNumberData[data.modelNumber] = data.modelNumber;
      modelNumberData.push(data.modelNumber);
    });
    if(modelNumberData){
      modelNumberData = modelNumberData.filter(function (value, index, array) { 
        return array.indexOf(value) === index;
      });
      modelNumberData.forEach((data, index) => {
        modelNumberAutocomplete.push({ label: data })
      });
    }
  }

  // console.log(modelNumberAutocomplete);
  const formatDB = ReadRowsSupabase({'session':session,'table':'format'});
  let formatData = [];
  // let formatSuffixData = [];
  let formatSuffixId = [];

  if(formatDB?.data){
    formatDB.data.forEach((data, index) => {
      formatData[data.id] = {id:data.id,name:data.name,suffix:data.suffix};
      // formatSuffixData[data.id] = data.suffix;
      formatSuffixId[data.suffix] = data.id;
    });
  }

  const coverTypeDB = ReadRowsSupabase({'session':session,'table':'coverType'});
  let coverTypeData = [];

  if(coverTypeDB?.data){
    coverTypeDB.data.forEach((data, index) => {
      coverTypeData[data.id] = data.name;
    });
  }

  const batchSizeDB = ReadRowsSupabase({'session':session,'table':'batchSizes'});
  let batchSizeData = [];

  if(batchSizeDB?.data){
    batchSizeDB.data.forEach((data, index) => {
      batchSizeData[data.id] = data.name;
    });
  }

  const [packagingOrder, setPackagingOrder] = React.useState(null)
  const [batchSize, setBatchSize] = React.useState(1)
  const [fieldPackagingOrderError, setFieldPackagingOrderError] = React.useState(null)
  const [requestedDate, setRequestedDate] = React.useState(format(new Date(new Date().getTime() + (24 * 60 * 60 * 1000)),'yyyy-MM-dd'))
  // const [fillingLine, setFillingLine] = React.useState('')
  const [formatDenalt, setFormat] = React.useState('')
  const [orderedQuantity, setOrderedQuantity] = React.useState(null)
  const [labelNumber, setLabelNumber] = React.useState('')
  // const [itemNumber, setItemNumber] = React.useState('')
  const [labelUPC, setLabelUPC] = React.useState('')
  const [labelUPCMessage, setLabelUPCMessage] = React.useState('Choose a valid combination for Volume and Product for filling the label UPC field')
  const [coverType, setCoverType] = React.useState(1)
  // const [floatRequired, setFloatRequired] = React.useState(true)
  const [errorField, setErrorField] = React.useState(false);

  const [alertError, setAlertError] = React.useState(false)

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const createLabelUpc = (formatValue,labelNumberValue) => {
    console.log(formatValue);
    console.log(labelNumberValue);
    // setItemNumber(formatSuffixData[formatValue] + labelNumberValue)
    // const itemNumber = labelNumberValue + formatSuffixData[formatValue];
    const itemNumber = labelNumberValue + formatValue;
    if(itemNumberUpcData[itemNumber]){
      setLabelUPC(itemNumberUpcData[itemNumber])
      setLabelUPCMessage("")
    }else{
      setErrorField(true)
      setLabelUPC("")
      setLabelUPCMessage("There's no matching UPC for the product with this format. Please review those fields")
    }
  };

  const addNewItem = async (e) => {
    e.preventDefault()
    try {
      const updates = {
        packagingOrder : packagingOrder,
        batchSize : batchSize,
        requestedDate : requestedDate,
        // fillingLine : fillingLine,
        format : formatSuffixId[formatDenalt],
        orderedQuantity : orderedQuantity,
        labelNumber : labelNumber,
        labelUPC : labelUPC,
        coverType : coverType,
        // floatRequired : floatRequired,
      }

      if(!packagingOrder){
        setFieldPackagingOrderError(true)
        const alertError = {details:"Please fill in the required fields"};
        throw alertError
      }else{
        setFieldPackagingOrderError(false)
      };

      const { data, error } = await supabase
      .from('requestedJobs')
      .insert(updates)
      .select("*")

      if(data){
        setOpen(false);
        navigate(0);
      }

      if (error) {
        throw error
      } 

    } catch (error) {
      setAlertError(error);
    } 
  }


  return (
    <>
      <Button variant="outlined" onClick={handleClickOpen}>
        Add New Requested Jobs
      </Button>
      <Dialog 
        fullWidth={true}
        maxWidth={"sm"}
        open={open} 
        onClose={handleClose}
      >
        <DialogTitle>New Requested Jobs</DialogTitle>
        <DialogContent>
          <form id="formAddRequestedJobs" onSubmit={addNewItem}>
          <Stack spacing={3}>
            {alertError &&
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <div>{alertError.details}</div>
                <div>{alertError.message}</div>
              </Alert>
            }
            <TextField
              autoFocus
              required
              error={fieldPackagingOrderError}
              margin="dense"
              id="product-batch"
              label="Batch number"
              type="text"
              fullWidth
              variant="outlined"
              onChange={(e) => {setPackagingOrder(e.target.value); setFieldPackagingOrderError(false);setAlertError(false)}}
            />
            {batchSizeData ? (
              <FormControl fullWidth>
                <InputLabel shrink required id="batch-size-label-id">Batch size</InputLabel>
                <RadioGroup
                  row
                  aria-labelledby="batch-size-label-id"
                  value={batchSize}
                  name="batch-size-buttons-group"
                  onChange={(e) => setBatchSize(e.target.value)}
                >
                  {batchSizeData.map((data, index) => {
                    return (
                      <FormControlLabel key={"batch-size"+index} value={index} control={<Radio />} label={data} />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            ):("")} 
            {/* <TextField
              required
              margin="dense"
              id="Requested date"
              label="Requested date"
              type="date"
              defaultValue={format(new Date(new Date().getTime() + (24 * 60 * 60 * 1000)),'yyyy-MM-dd')}
              fullWidth
              variant="outlined"
              onChange={(e) => setRequestedDate(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }} 
            /> */}
            {/* {fillingLineData ? (
            <FormControl fullWidth>
              <InputLabel id="filling-line-label-id">Filling line</InputLabel>
              <Select
                labelId="filling-line-label-id"
                id="Filling line"
                value={fillingLine}
                label="Filling line"
                onChange={(e) => setFillingLine(e.target.value)}
              >
                {fillingLineData.map((data, index) => {
                  return (
                    <MenuItem key={"filling"+index} value={index}>{data}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            ):("")} */}
            <TextField
              required
              margin="dense"
              id="Ordered quantity"
              label="Ordered quantity"
              type="number"
              fullWidth
              variant="outlined"
              onChange={(e) => setOrderedQuantity(e.target.value)}
            />
            {modelNumberAutocomplete ?
              (
                <Autocomplete
                  freeSolo
                  disablePortal
                  autoHighlight
                  autoSelect
                  id="model-number"
                  options={modelNumberAutocomplete}
                  onChange={(e) => {
                    // console.log(e._reactName);
                    if(e._reactName === 'onClick'){
                      setLabelNumber(e.target.innerText);
                      createLabelUpc(formatDenalt,e.target.innerText);
                    }else{
                      setLabelNumber(e.target.value);
                      createLabelUpc(formatDenalt,e.target.value);
                    }
                  }}
                  renderInput={(params) => <TextField {...params} fullWidth type="text" variant="outlined" required label="Product" />}
                />
              ) : (
                <TextField
                  required
                  margin="dense"
                  id="model-number"
                  label="Product"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {setLabelNumber(e.target.value);createLabelUpc(formatDenalt,e.target.value);}}
                />
              )
            }
            
            {/* <FormControl fullWidth> */}
            {formatData ?
              (
                <Autocomplete
                  disablePortal
                  autoHighlight
                  autoSelect
                  id="format-id"
                  options={formatData}
                  getOptionLabel={(option) => option.suffix + ' - ' + option.name}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  onChange={(e, option) => {
                    console.log(option);
                    // console.log(e.target.value);
                    // console.log(e.target.innerText);
                    // if(e._reactName === 'onClick'){
                      setFormat(option.suffix);
                      createLabelUpc(option.suffix,labelNumber);
                    // }else{
                    //   setFormat(e.target.value.id);
                    //   createLabelUpc(e.target.value.suffix,labelNumber);
                    // }
                  }}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      {option.suffix} - {option.name}
                    </Box>
                  )}
                  renderInput={(params) => <TextField {...params} fullWidth type="text" variant="outlined" required label="Volume" />}
                />
              ) : (
              <>
                <TextField
                  required
                  margin="dense"
                  id="format-id"
                  label="Product suffix"
                  type="text"
                  fullWidth
                  variant="outlined"
                  onChange={(e) => {setFormat(e.target.value);createLabelUpc(e.target.value,labelNumber);}}
                />
                {/* <Select
                  required
                  labelId="format-label-id"
                  id="format-id"
                  value={formatDenalt}
                  label="Volume"
                  onChange={(e) => {setFormat(e.target.value);createLabelUpc(e.target.value,labelNumber);}}
                >
                  {formatData.map((data, index) => {
                    return (
                      <MenuItem key={"format"+index} value={index}>{data}</MenuItem>
                    );
                  })}
                </Select> */}
              </>
              )
            }
            {/* </FormControl> */}
            <TextField
              required
              margin="dense"
              id="Label UPC"
              label="Label UPC"
              type="text"
              fullWidth
              variant="outlined"
              value={labelUPC}
              disabled
              error={errorField}
              InputProps={{ readOnly: true }}
              InputLabelProps={{ shrink: labelUPC ? true : false }}
              onChange={(e) => setLabelUPC(e.target.value)}
              helperText={labelUPCMessage}
            />
            {/* {coverTypeData ? (
            <FormControl fullWidth>
              <InputLabel required id="cover-type-label-id">Cover type</InputLabel>
              <Select
                required
                labelId="cover-type-label-id"
                id="cover-type-id"
                value={coverType}
                label="Cover type"
                onChange={(e) => setCoverType(e.target.value)}
              >
                {coverTypeData.map((data, index) => {
                  return (
                    <MenuItem key={"cover"+index} value={index}>{data}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            ):("")} */}
            {coverTypeData ? (
            <FormControl fullWidth>
              <InputLabel shrink required id="cover-type-label-id">Cover type</InputLabel>
              <RadioGroup
                row
                aria-labelledby="cover-type-label-id"
                defaultValue="1"
                value={coverType}
                name="cover-buttons-group"
                onChange={(e) => setCoverType(e.target.value)}
              >
                {coverTypeData.map((data, index) => {
                  return (
                    <FormControlLabel key={"cover"+index} value={index} control={<Radio />} label={data} />
                  );
                })}
              </RadioGroup>
            </FormControl>
            ):("")}
            {/* <FormControlLabel
              control={<Switch color="primary" defaultChecked/>}
              label="Float Required"
              labelPlacement="end"
              onChange={(e) => setFloatRequired(e.target.checked)}
            /> */}
          </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button form='formAddRequestedJobs' color="success" variant="contained" type="submit">Add</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddRequestedJobs