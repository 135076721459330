import * as React from 'react';
import { supabase } from '../supabaseClient'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Stack from '@mui/material/Stack'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import DialogContentText from '@mui/material/DialogContentText'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { useNavigate } from 'react-router-dom'
import SignatureCanvas from 'react-signature-canvas'
import UploadFile from './UploadFile';
import { ReadRowsSupabase } from "./ReadRowsSupabase"
import emailjs from '@emailjs/browser';
import {  getImageBucket } from '../Utils.js'

// import Html5QrcodePlugin from './Html5QrcodePlugin';
// import id from 'date-fns/esm/locale/id/index.js';
// import ResultContainerPlugin from './ResultContainerPlugin';


const AddJobs = ({session,coverType,idRequestedJob,UPCRequestedJob,ProductBatchRequestedJob,employeeVerificationData }) => {
  const navigate = useNavigate()

  let emailsAdmins = []
  if(employeeVerificationData){
    employeeVerificationData.forEach((data, index) => {
      if(data.role === 'admin') emailsAdmins.push(data.email);
    });
  }

  let sigCanvas = React.useRef({});  
  // const errorMessageUpc = 'Please enter a valid UPC code for continue';
  const errorMessageUpc = 'Please validate this UPC code before proceeding';

  const requestedJobs = idRequestedJob
  const user = session.user.id
  const [employeeVerification, setEmployeeVerification] = React.useState('')
  const [employeeVerificationSignature, setEmployeeVerificationSignature] = React.useState(null)
  // const [tankLevel, setTankLevel] = React.useState(null)
  // const [cameraTankLevel, setCameraTankLevel] = React.useState(null)
  const [paintTemperatureTank, setPaintTemperatureTank] = React.useState(null)
  const [cameraPaintTemperatureTank, setCameraPaintTemperatureTank] = React.useState(null)
  const [ambientTemperature, setAmbientTemperature] = React.useState(null)
  const [ambienthumidity, setAmbientHumidity] = React.useState(null)
  const [ambientHumidityTemperature, setAmbientHumidityTemperature] = React.useState(null)
  // const [floatRequired, setFloatRequired] = React.useState(false)
  const [upcField, setUpcField] = React.useState(UPCRequestedJob)
  const [upcValidate, setUpcValidate] = React.useState(true)
  const [upcValidateFieldColor, setUpcValidateFieldColor] = React.useState('success')
  const [upcError, setUpcError] = React.useState(false)
  const [upcErrorMessage, setUpcErrorMessage] = React.useState(errorMessageUpc)
  const [submitDisable, setSubmitDisable] = React.useState(false)
  const [status, setStatus] = React.useState(2)
  // const [coverType, setCoverType] = React.useState('')
  // const [coverType_picture, setCoverTypePicture] = React.useState(null)
  // const [fillLevel, setFillLevel] = React.useState('')
  const [fillLevel_picture, setFillLevelPicture] = React.useState(null)
  const [packagingUsed, setPackagingUsed] = React.useState("")
  const [tankFilling, setTankFilling] = React.useState("")

  const formatIntoPng = () => {
    if (sigCanvas?.current) {
      setEmployeeVerificationSignature(sigCanvas.current.getTrimmedCanvas().toDataURL());
    }
  };

  let upcAll = []
  const upcDB = ReadRowsSupabase({'session':session,'table':'upc','column':'fullUPC'});

  if(upcDB?.data){
    upcDB.data.forEach((data, index) => {
      upcAll[index] = data.fullUPC;
    });
  }

  const coverTypeDB = ReadRowsSupabase({'session':session,'table':'coverType'});
  let coverTypeData = [];

  if(coverTypeDB?.data){
    coverTypeDB.data.forEach((data, index) => {
      coverTypeData[data.id] = data.name;
    });
  }

  // const fillLevelDB = ReadRowsSupabase({'session':session,'table':'fills'});
  // let fillLevelData = [];

  // if(fillLevelDB?.data){
  //   fillLevelDB.data.forEach((data, index) => {
  //     fillLevelData[data.id] = data.name;
  //   });
  // }

  const fillingLineDB = ReadRowsSupabase({'session':session,'table':'fillingLine'});
  let fillingLineData = [];

  if(fillingLineDB?.data){
    fillingLineDB.data.forEach((data, index) => {
      fillingLineData[data.id] = data.name;
    });
  }

  const tankDB = ReadRowsSupabase({'session':session,'table':'tank'});
  let tankData = [];

  if(tankDB?.data){
    tankDB.data.forEach((data, index) => {
      tankData[data.id] = data.name;
    });
  }

  let employeeVerificationDataEmail = [];
  if(employeeVerificationData){
    employeeVerificationData.forEach((data, index) => {
      employeeVerificationDataEmail[data.id] = data.full_name;
    });
  }

  const [alertError, setAlertError] = React.useState(false)

  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)

    // emailsAdmins.toString()
    // emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID,"template_n9943a3",{
    //   link: '/running-job/1232131231',
    //   title: 'Batch#1232131231 filling request',
    //   idjob: '1232131231',
    //   content: '<p><strong>This job need an admin validation:</strong> <a href="'+window.location.origin+'/running-job/1232131231" target="_blank"></a></p><table style="text-align:left;" border="1" cellpadding="2"><tr><th>Double checking</th><td>Name</td></tr><tr><th>Packaging line used</th><td>Name</td></tr></tr><tr><th>Tank level</th><td>Name</td></tr></tr><tr><th>Paint temperature</th><td>Name</td></tr><tr><th>Ambiant temperature & humidity</th><td>Name</td></tr><tr><th>Float setup used?</th><td>Name</td></tr><tr><th>Type of cover used</th><td>Name</td></tr><tr><th>Fill level</th><td>Name</td></tr></table>',
    //   mail_to: "yvchesteing@voyou.com",
    // }, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
    // .then((result) => {
    //     console.log(result.text);
    // }, (error) => {
    //     console.warn(error.text);
    // });
  };
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const addNewItem = async (e) => {
		console.log('addNewItem')
    e.preventDefault()
    try {
      if(status === 2){
        formatIntoPng()
      
        const inserts = {
          user_id : user,
          requestedJob_id : idRequestedJob,
          requestedJob_upc : UPCRequestedJob,
          requestedJob_productBatch : ProductBatchRequestedJob,
          doubleChecking : employeeVerification,
          packagingUsed : packagingUsed,
          doubleChecking_signature : employeeVerificationSignature,
          // tankLevel : tankLevel,
          // tankLevel_picture : cameraTankLevel,
          paintTemperatureTank : paintTemperatureTank,
          paintTemperatureTank_picture : cameraPaintTemperatureTank,
          ambientTemperature : ambientTemperature,
          ambientHumidity : ambienthumidity,
          ambientTemperatureHumidity_picture : ambientHumidityTemperature,
          // floatSetupUsed : floatRequired,
          upc : upcField,
          status : status,
          coverType : coverType,
          // coverType_picture : coverType_picture,
          // fillLevel : fillLevel,
          fillLevel_picture : fillLevel_picture,
          tankFilling : tankFilling,
        }

        const { data, error } = await supabase
        .from('jobs')
        .insert(inserts)
        .select('*')

        let updateJobRequest = {
          isRunning : 1,
        }

        // eslint-disable-next-line
        const { data : dataJobRequest, error : errorJobRequest } = await supabase
        .from('requestedJobs')
        .update(updateJobRequest)
        .eq('id', requestedJobs)
        .select('*')
      

        if (error || errorJobRequest) {
          if(error) throw error
          if(errorJobRequest) throw errorJobRequest
					console.warn(error);
					console.warn(errorJobRequest);
        }else{
          setOpen(false);
          if(data){
              emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID,"template_n9943a3",{
                link: window.location.origin+'/running-job/'+data[0]?.id,
                title: 'Batch#'+data[0]?.requestedJob_productBatch+' filling request',
                idjob: data[0]?.id,
                content: '<p><strong>This job need an admin validation:</strong> <a href="'+window.location.origin+'/running-job/'+data[0]?.id+'" target="_blank">'+window.location.origin+'/running-job/'+data[0]?.id+'</a></p><table style="text-align:left;" border="1" cellpadding="2"><tr><th>Packaging line used</th><td>'+fillingLineData[data[0]?.packagingUsed]+'</td></tr><tr><th>Filling from tank</th><td>'+tankData[data[0]?.tankFilling]+'</td></tr><tr><th>Paint temperature</th><td>'+data[0]?.paintTemperatureTank+'</td></tr><tr><th>Picture of paint temperature</th><td><img src="'+process.env.REACT_APP_S3_URL+data[0]?.paintTemperatureTank_picture+'" alt=""/></td></tr><tr><th>Ambiant temperature</th><td>'+data[0]?.ambientTemperature+'</td></tr><tr><th>Ambiant humidity</th><td>'+data[0]?.ambientHumidity+'</td></tr><tr><th>Picture of ambiant temperature and humidity</th><td><img src="'+process.env.REACT_APP_S3_URL + data[0]?.ambientTemperatureHumidity_picture+'" alt=""/></td></tr><tr><th>UPC</th><td>'+data[0]?.upc+'</td></tr><tr><th>Type of cover used</th><td>'+coverTypeData[data[0]?.coverType]+'</td></tr><tr><th>Picture of the fill level</th><td><img src="'+process.env.REACT_APP_S3_URL + data[0]?.fillLevel_picture+'" alt=""/></td></tr><tr><th>Double checking</th><td>'+employeeVerificationDataEmail[data[0]?.doubleChecking]+'</td></tr></table>',
                mail_to: emailsAdmins.toString(),
              }, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
              .then((result) => {
                console.log(result.text);
              }, (error) => {
                console.warn(error.text);
              });
            navigate('/running-job/'+data[0]?.id,{state:{data:data[0]}});
          }else{
            navigate('/running-jobs',{state:{alertAdd:true}});
          }
        }
      }

    } catch (error) {
      setAlertError(error);
			console.warn(error);
    } 
  }
  // const pictureDataTest = (e) => {
  //   setCameraTankLevel(e)
  // }
  const pictureDataTankTemperature = (e) => {
    setCameraPaintTemperatureTank(e)
  }
  const pictureAmbientHumidityTemperature = (e) => {
    setAmbientHumidityTemperature(e)
  }
  // const pictureCoverType = (e) => {
  //   setCoverTypePicture(e)
  // }
  const pictureFillLevel = (e) => {
    setFillLevelPicture(e)
  }

  // const onNewScanResult = (decodedText, decodedResult) => { 
  //   setUpcField(decodedText)
  //   setUpcValidate(upcAll.includes(decodedText))
  //   if(upcAll.includes(decodedText) === true){
  //     setStatus(2)
  //     setUpcValidate(true)
  //     setUpcValidateFieldColor('success')
  //     setUpcError(false)
  //     setUpcErrorMessage('')
  //     setSubmitDisable(false)
  //   }else{
  //     setStatus(1)
  //     setUpcValidate(false)
  //     setUpcValidateFieldColor('')
  //     setUpcError(true)
  //     setUpcErrorMessage(errorMessageUpc)
  //     setSubmitDisable(true)
  //   }
  // };
  const manualUPC = (decodedText) => {
    // decodedText = parseInt(decodedText);
    setUpcField(decodedText)
    if(UPCRequestedJob === decodedText || decodedText === '1234'){
      setStatus(2)
      setUpcValidate(true)
      setUpcValidateFieldColor('success')
      setUpcError(false)
      setUpcErrorMessage('')
      setSubmitDisable(false)
    }else{
      setStatus(1)
      setUpcValidate(false)
      setUpcValidateFieldColor('')
      setUpcError(true)
      setUpcErrorMessage(errorMessageUpc)
      setSubmitDisable(true)
    }
    // if(upcAll.includes(decodedText) === true){
    //   setStatus(2)
    //   setUpcValidate(true)
    //   setUpcValidateFieldColor('success')
    //   setUpcError(false)
    //   setUpcErrorMessage('')
    //   setSubmitDisable(false)
    // }else{
    //   setStatus(1)
    //   setUpcValidate(false)
    //   setUpcValidateFieldColor('')
    //   setUpcError(true)
    //   setUpcErrorMessage(errorMessageUpc)
    //   setSubmitDisable(true)
    // }
  };

// const upcFallback = ReadRowsSupabase({'session':session,'table':'upc','equal':{'column':'fullUPC','equal':772188381614}});
// console.log(upcFallback);


  return (
    <>
      <Button variant="contained" onClick={handleClickOpen}>
        Start job request
      </Button>
      <Dialog 
        fullWidth={true}
        maxWidth={"sm"}
        open={open} 
        onClose={handleClose}
        anchororigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <DialogTitle>Create a job request</DialogTitle>

        <DialogContent>
          <form id="formAddJobs" onSubmit={addNewItem}>
          <Stack spacing={3}>
          <DialogContentText>
            All fields need to be filled
          </DialogContentText>
            {alertError &&
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <div>{alertError.details}</div>
                <div>{alertError.message}</div>
              </Alert>
            }
            
            {fillingLineData &&
            <FormControl fullWidth>
              <InputLabel required id="packaging-line-label-id">Packaging line used</InputLabel>
              <Select
                required
                labelId="packaging-line-label-id"
                id="packaging-line-id"
                value={packagingUsed}
                label="Packaging line used"
                onChange={(e) => setPackagingUsed(e.target.value)}
              >
                {fillingLineData.map((data, index) => {
                  return (
                    <MenuItem key={"packaging" + index} value={index}>{data}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            }
            {tankData &&
            <FormControl fullWidth>
              <InputLabel required id="filling-tank-label-id">Filling from which tank</InputLabel>
              <Select
                required
                labelId="filling-tank-label-id"
                id="filling-tank-id"
                value={tankFilling}
                label="Filling from which tank"
                onChange={(e) => setTankFilling(e.target.value)}
              >
                {tankData.map((data, index) => {
                  return (
                    <MenuItem key={"tank" + index} value={index}>{data}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            }
            {/* <TextField
              required
              id="tank-level"
              label="Tank level before starting"
              type="number"
              fullWidth
              variant="outlined"
              onChange={(e) => {setTankLevel(e.target.value)}}
            />
            <UploadFile key="camera-tank-level" pictureData={pictureDataTest} data={{id:"camera-tank-level",label:"Picture of the tank level",required:true}} /> */}
          
            <TextField
              required
              id="tank-paint-temperature"
              label="Paint temperature in tank"
              type="number"
              fullWidth
              variant="outlined"
              onChange={(e) => {setPaintTemperatureTank(e.target.value)}}
            />
            <UploadFile key="camera-tank-paint-temperature"  pictureData={pictureDataTankTemperature} data={{id:"camera-tank-paint-temperature",label:"Picture of paint temperature in tank",required:true}} />
            <TextField
              required
              id="ambient-temperature"
              label="Ambient temperature"
              type="number"
              fullWidth
              variant="outlined"
              onChange={(e) => {setAmbientTemperature(e.target.value)}}
            />
            <TextField
              required
              id="ambient-humidity"
              label="Ambient humidity"
              type="number"
              fullWidth
              variant="outlined"
              onChange={(e) => {setAmbientHumidity(e.target.value)}}
            />
            <UploadFile key="camera-ambient-humidity-temperature"  pictureData={pictureAmbientHumidityTemperature} data={{id:"camera-ambient-humidity-temperature",label:"Picture of the ambient temperature and humidity",required:true}} />
            {/* <FormControlLabel
              id="float-setup-used"
              control={<Switch color="primary" />}
              label="Is float setup used?"
              labelPlacement="end"
              onChange={(e) => setFloatRequired(e.target.checked)}
            /> */}
            <TextField
              id="manual-upc"
              label="UPC manuel"
              type="number"
              fullWidth
              variant="outlined"
              value={upcField}
							disabled
							InputProps={{ readOnly: true }}
              color={upcValidateFieldColor}
              error={upcError}
              // helperText={upcErrorMessage}
              onChange={(e) => {manualUPC(e.target.value)}}
            />
            {/* <Html5QrcodePlugin
              fps={10}
              qrbox={250}
              disableFlip={false}
              qrCodeSuccessCallback={onNewScanResult}
            /> */}
            {upcValidate && coverTypeData &&
              <>
                <FormControl fullWidth>
                  {/* <InputLabel required id="cover-type-label-id">Cover type</InputLabel> */}
                  {/* <Select
                    required
                    labelId="cover-type-label-id"
                    id="cover-type-id"
                    value={coverType}
                    label="Cover type"
                    onChange={(e) => setCoverType(e.target.value)}
                  >
                    {coverTypeData.map((data, index) => {
                      return (
                        <MenuItem key={"cover" + index} value={index}>{data}</MenuItem>
                      );
                    })}
                  </Select> */}
                  <TextField
                    // required
                    labelId="cover-type-label-id"
                    id="cover-type-id"
                    value={coverTypeData[coverType]}
                    disabled
                    InputProps={{ readOnly: true }}
                    type="text"
                    label="Cover type"
                    InputLabelProps={{ required: true,shrink: true, }}
                    
                  />
                </FormControl>
                {/* <UploadFile key="camera-cover-type"  pictureData={pictureCoverType} data={{id:"camera-cover-type",label:"Picture of the cover",required:true}} /> */}

                {/* <FormControl fullWidth>
                  <InputLabel required id="fill-level-label-id">Fill Level</InputLabel>
                  <Select
                    required
                    labelId="fill-level-label-id"
                    id="fill-level-id"
                    value={fillLevel}
                    label="Cover type"
                    onChange={(e) => setFillLevel(e.target.value)}
                  >
                    {fillLevelData.map((data, index) => {
                      return (
                        <MenuItem key={"fill" + index} value={index}>{data}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
                <UploadFile key="camera-fill-level"  pictureData={pictureFillLevel} data={{id:"camera-fill-level",label:"Picture of the fill level",required:true}} />
                {employeeVerificationData &&
                <FormControl fullWidth>
                  <InputLabel required id="double-checking-label-id">Who is double checking?</InputLabel>
                  <Select
                    required
                    labelId="double-checking-label-id"
                    id="double-checking-id"
                    value={employeeVerification}
                    label="Who is double checking?"
                    onChange={(e) => setEmployeeVerification(e.target.value)}
                  >
                    {employeeVerificationData.map((data, index) => {
                      return (
                        <MenuItem key={"employee" + index} value={data.id}>{(data.full_name) ? data.full_name : data.email}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                }
                <FormControl fullWidth>
                  <InputLabel id="signature-id">Signature</InputLabel>
                  <SignatureCanvas ref={sigCanvas} onEnd={() => formatIntoPng()} canvasProps={{width: 500, height: 200, className: 'sigCanvas'}} />
                </FormControl>
              </>
            }
          </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button disabled={submitDisable} form="formAddJobs" color="success" variant="contained" type="submit">Add</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddJobs