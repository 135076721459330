import { Link, useLocation } from "react-router-dom";
import { supabase } from '../supabaseClient'

import { useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import LogoutIcon from '@mui/icons-material/Logout';

export default function GlobalHeader(props) {
  const drawerWidth = 240;
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const location = useLocation();
  const current_location = location.pathname;

  const menu = [
    {key:'dashboard',name:'Dashboard',link:'/'},
    {key:'requested-jobs',name:'Requested Jobs',link:'/requested-jobs'},
    {key:'running-jobs',name:'Running Jobs',link:'/running-jobs'},
    {key:'completed-jobs',name:'Completed Jobs',link:'/completed-jobs'},
  ]

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Toolbar />
      <Divider />
      <List>
        {menu.map((data, index) => {
          let activeMenu = false;
          if(data.link === current_location) activeMenu = true;
          return (
            <ListItem key={data.key} disablePadding>
              <ListItemButton sx={{ textAlign: 'center' }} component={Link} to={data.link} selected={activeMenu}>
                <ListItemText>{data.name}</ListItemText>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;



  return (
    <>
      <AppBar component="nav"
        sx={{
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          ml: { lg: `${drawerWidth}px` },
        }}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { lg: 'none' }, mr: 2}}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{ flexGrow: 1}}
          >
            <Link to="/">
              <svg fill="none" height="27" viewBox="0 0 142 27" width="142" xmlns="http://www.w3.org/2000/svg"><g fill="#fff"><path d="m0 26.2659 4.59029-26.2659h11.42501c8.3891 0 12.905 3.93736 11.3912 12.5862-1.7344 9.8871-7.8453 13.6811-16.1993 13.6811h-11.2072zm9.61485-6.1272h1.81145c3.9748 0 6.506-2.1175 7.3732-7.0774.9416-5.47465-.575-6.93409-5.1328-6.93409h-1.6289z"/><path d="m24.9399 26.2659 4.5944-26.2659h21.2211l-1.085 6.12721h-12.6872l-.6521 3.75714h10.7377l-1.0471 6.12995h-10.7391l-.7238 4.1244h13.1973l-1.0836 6.1272z"/><path d="m66.8818 14.3391.7225-5.98245 1.4475-8.35665h8.1687l-4.589 26.2659h-7.99l-6.7982-14.2996-.7238 5.9825-1.4476 8.3171h-8.1699l4.593-26.2659h7.9914z"/><path d="m72.2163 26.2659 13.921-26.2659h8.6747l4.7391 26.2659h-8.4961l-.3977-3.4623h-8.496l-1.5924 3.4623zm16.8135-18.35023-4.3008 9.41333h5.3858z"/><path d="m99.1128 26.2659 4.5902-26.2659h8.533l-3.508 20.1387h11.714l-1.085 6.1272z"/><path d="m118.135 0h23.865l-1.196 6.89584h-7.662l-3.4 19.37006h-8.535l3.402-19.37006h-7.668z"/></g></svg>
            </Link>
          </Box>
          <Box>
            <IconButton size="large" aria-label="search" color="inherit" onClick={() => supabase.auth.signOut()}>
              <LogoutIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ width: { lg: drawerWidth }, flexShrink: { lg: 0 } }}>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', lg: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
    </>
  );
}