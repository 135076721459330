import * as React from 'react';
import AddNotice from './AddNotice'
import { supabase } from '../supabaseClient'

import { Link } from "react-router-dom";
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { format } from 'date-fns';
import Grid from '@mui/material/Unstable_Grid2';
import CloseIcon from '@mui/icons-material/Close';
import { ReadRowsSupabase } from "./ReadRowsSupabase"
import { GetUserRole } from '../Utils.js'
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

const Dashboard = ({ session }) => {

  const noticesDB = ReadRowsSupabase({'session':session,'table':'notices'})
  const userRole = GetUserRole({'session':session})
  const [openError, setOpenError] = React.useState(false)
  const [alertErrorMessage, setAlertErrorMessage] = React.useState(false)

  const closeAlertError = () => {
    setOpenError(false)
  };

  const removeNotice = async (e, id) => {
    console.log(e);
    e.preventDefault()

    console.log(id);
    
    

    try {
      const update = {
        deleted : true,
      }

      const { data, error } = await supabase
      .from('notices')
      .update(update)
      .eq('id', id)
      .select('*')

      if(data){
        document.getElementById('noticeItem'+id).style.display = "none"
      }

      if (error) {
        throw error
      } 

    } catch (error) {
      setAlertErrorMessage(error)
      setOpenError(true)
    } 
  }
  
  let noticesData = [];

  if(noticesDB?.data){
    noticesDB.data.forEach((data, index) => {
      noticesData[data.id] = {id:data.id,date:data.created_at,notice:data.message}
    });
  }

  const requestedJobsDB = ReadRowsSupabase({'session':session,'table':'requestedJobs','equal':{'column':'isRunning','equal':false}});
  const runningJobsDB = ReadRowsSupabase({'session':session,'table':'jobs','notequal':{'column':'status','equal':4}});
  return (
    <div className="vy_dashboard">
      <Typography gutterBottom variant="h4">
        My Dashboard
      </Typography>
        <Snackbar open={openError} autoHideDuration={6000} onClose={closeAlertError}>
          <Alert onClose={closeAlertError} severity="error" sx={{ width: '100%' }}>
            <AlertTitle>Error</AlertTitle>
            <div>{alertErrorMessage.details}</div>
            <div>{alertErrorMessage.message}</div>
          </Alert>
        </Snackbar>
        <Grid container spacing={4}>
          {(noticesData || userRole === 'admin') &&
            <Grid xs={12}>
              {/* <Alert sx={{maxHeight:'300px'}} severity="info"> */}
              <Alert className="vy_notices" severity="info">
                <AlertTitle>General notice</AlertTitle>
                  {noticesData.map((data, index) => {
                    return (
                      <div id={'noticeItem'+data.id} className="vy_notices_item" key={"notice" + index}>
                        {index >= 2 &&
                          <Divider />
                        }
                        <div>
                          <p>{data.notice}</p>
                          <small>{format(new Date(data.date.replace('T', ' ')), 'MMM. dd, yyyy - H:mma')}</small>
                          {userRole === 'admin' && 
                            <>
                              <IconButton className="vy_notices_item_delete"  onClick={(e) => { removeNotice(e,data.id) }} aria-label="Example">
                                <CloseIcon color="error" />
                              </IconButton>
                            </>
                          }
                        </div>
                      </div>
                    );
                  })}
              </Alert>
              {userRole === 'admin' && 
                <>
                  <Stack direction="row" spacing={2} marginTop="10px" justifyContent="flex-end">
                    <AddNotice session={session} />
                  </Stack>
                </>
              }
            </Grid>
          }
          <Grid xs={12} sm={6}>
            <Card>
              <CardActionArea component={Link} to="/requested-jobs">
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Requested Jobs {requestedJobsDB?.data && '('+requestedJobsDB.data.length+')'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    List of all the requested jobs available. Access this page for starting a job request.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid xs={12} sm={6}>
            <Card>
              <CardActionArea component={Link} to="/running-jobs">
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Running Jobs {runningJobsDB?.data && '('+runningJobsDB.data.length+')'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    List of all running jobs. 
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid xs={12} sm={6}>
            <Card>
              <CardActionArea component={Link} to="/completed-jobs">
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Completed Jobs
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    List of all completed jobs.
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          <Grid xs={12} sm={6}>
            <Card>
              <CardActionArea component={Link} to="/account">
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    My Account
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Edit your profil
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
    </div>
  );
}
export default Dashboard