// import logo from './logo.svg';
import './App.scss';

import './index.css'
import { useState, useEffect, useLayoutEffect } from 'react'
import { supabase } from './supabaseClient'
import Auth from './components/Auth'
import Account from './components/Account'
// import Filling from './Filling'

import GlobalHeader from "./components/GlobalHeader.js";
import RequestedJobs from "./components/RequestedJobs.js";
import SingleRequestedJobs from "./components/SingleRequestedJobs.js";
import RunningJobs from "./components/RunningJobs.js";
import SingleRunningJobs from "./components/Job.js";
import CompletedJobs from "./components/CompletedJobs.js";
// import GlobalFooter from "./components/GlobalFooter.js";
import Dashboard from "./components/Dashboard.js";

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import CircularProgress from '@mui/material/CircularProgress';

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";

import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      light: '#194E78',
      main: '#011B39',
      dark: '#171E2D',
      contrastText: '#fff',
    },
    secondary: {
      light: '#E8F6FA',
      main: '#94DCF5',
      dark: '#2B98BF',
      contrastText: '#000',
    },
    error: {
      light: '#ED3D4E',
      main: '#CF1C2D',
      dark: '#A52531',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily:'"Montserrat","Helvetica","Arial",sans-serif'
  }
});


export default function App() {
  const Wrapper = ({children}) => {
    const location = useLocation();
    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);
    return children
  } 

  const drawerWidth = 240;
  // const navigation = useNavigate();

  // eslint-disable-next-line
  const [user, setUser] = useState({})
  const [loading, setLoading] = useState(true)
  const [session, setSession] = useState(null)

  useEffect(() => {
    async function getUserData(){
      await supabase.auth.getUser().then((value)=> {
        if(value.data?.user) {
          setUser(value.data.user)
        }
      })
    }
    
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session)
      setLoading(false)
      if(session){
        getUserData();
      }
    })
    supabase.auth.onAuthStateChange((event, session) => {
      if (event === 'SIGNED_OUT') {
        setSession(null)
      }
    })
  }, [])
  useEffect(() => {
    async function getUserInfo(){
      if(session){
        try {
          let { data, error, status } = await supabase
            .from("users")
            .select("*")
            .eq("id",session.user.id)
            .single()
          if(data){
            // console.log(data);
            if(data.full_name) session.user['full_name'] = data.full_name
            if(!data.full_name) session.user['full_name'] = data.email
            session.user['role_user'] = data.role
          }
          if (error && status !== 406) {
            throw error
          }
        } catch (error) {
          console.warn(error.message)
        } 
      } 
    }
    getUserInfo();
  },[user])

  return (
    <ThemeProvider theme={theme}>
      <Router>
      <Wrapper>
        <Box>
          <GlobalHeader/>
          <Box component="main" sx={{ flexGrow: 1, p: 3,ml:"auto",boxSizing:"border-box", width: { lg: `calc(100% - ${drawerWidth}px)` } }}>
            <Toolbar />
            {!session ? (
              loading ? (
                <CircularProgress />
              ): (
                <Auth />
              )
            ) : (
              <Routes>
                <Route path="/" element={<Dashboard session={session} />} />
                <Route path="/requested-jobs" element={<RequestedJobs session={session} />} />
                <Route path="/requested-job/:id" element={ <SingleRequestedJobs  session={session} /> } />
                <Route path="/running-jobs" element={<RunningJobs session={session} />} />
                <Route path="/running-job/:id" element={ <SingleRunningJobs  session={session} /> } />
                <Route path="/completed-jobs" element={<CompletedJobs  session={session}  />} />
                <Route path="/account" element={<Account key={session.user.id} session={session} />} />
              </Routes>
            )}
          </Box>
        </Box>
      </Wrapper>
      </Router>
    </ThemeProvider>
  )
}