import React, {useState} from "react";
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import { Upload } from "@aws-sdk/lib-storage";
import { S3Client } from "@aws-sdk/client-s3";

export default function UploadFile({pictureData, data}) {

  const [progressLoading , setProgress] = useState(null);
  const [errorField, setErrorField] = useState(false);
  const [errorFieldText, setErrorFieldText] = useState(null);

  function loading(progress){
    if(progress >= 0 && progress != null){
      return (
        <>
          <Box sx={{ width: '100%' }} mt={'10px !important'}>
            {progress === 0 ? (
              <LinearProgress />
            ): progress === 100 ? (
              <LinearProgress variant="determinate" value={progress} color="success" />
            ):(
              <LinearProgress variant="determinate" value={progress} />
            )}
          </Box>
        </>
      )
    }
  }
  
  function fileChange(e) {
    if(e.target.files[0]){
      const file = e.target.files[0];
      const timestamp = new Date().getTime();
      const filename = file.name.split('.')[0].replace(/[&\/\\#,+()$~%'":*?<>{}]/g, '').toLowerCase() + `_${timestamp}`;
      const fileExtension = file.name.split('.').pop();

      setProgress(0);
    
      const target = { Bucket:process.env.REACT_APP_S3_BUCKET_NAME, Key:filename+'.'+fileExtension, Body:file};
      const creds = {accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY_ID,secretAccessKey:process.env.REACT_APP_S3_ACCESS_KEY_SECRET};
      if(file.size <= 5242880){
        setErrorField(false)
        setErrorFieldText(null)
        try {
          const parallelUploads3 = new Upload({
            client: new S3Client({region:process.env.REACT_APP_S3_BUCKET_REGION,credentials:creds}),
            leavePartsOnError: false, // optional manually handle dropped parts
            params: target,
          });
      
          parallelUploads3.on("httpUploadProgress", (progress) => {
            setProgress(Math.round((progress.loaded / progress.total) * 100))
            pictureData(progress.Key)
            if(progressLoading === 100){
              pictureData(progress.Key)
            }
          });
      
          parallelUploads3.done();
        } catch (e) {
          console.warn(e);
        } 
      }else{
        setProgress(null)
        setErrorField(true)
        setErrorFieldText('File can\'t be larger then 5mb')
      }
    }else{
      setErrorField(false)
      setErrorFieldText(null)
      setProgress(null);
      pictureData(null)
    }
  }

  return(
    <React.Fragment>
      <TextField
        required={data.required}
        id={data.id}
        label={data.label}
        type="file"
        fullWidth
        variant="outlined"
        accept="image/*"
        capture="camera"
        error={errorField}
        helperText={errorFieldText}
        onChange={(e) => {fileChange(e)}}
        InputLabelProps={{
          shrink: true,
        }}
      />
      {loading(progressLoading)}
    </React.Fragment>
  )
};