import * as React from 'react';
import { supabase } from '../supabaseClient'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { format } from 'date-fns';
import TableSkeleton from "./TableSkeleton";
import { ReadRowsSupabase } from "./ReadRowsSupabase"

import {useNavigate} from 'react-router-dom';

let headers = ['Batch number','Created date','Finished date','User'];
let rows = [];

const titlePage = 'Completed Jobs';

const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '')
    .replace(/^-+|-+$/g, '');

const CompletedJobs = ({ session }) => {  
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(false)
  const [jobs, setJobs] = React.useState(null)
  const [profiles, setProfiles] = React.useState(null)


  const statusDB = ReadRowsSupabase({'session':session,'table':'jobsStatus'});
  let statusData = [];

  if(statusDB?.data){
    statusDB.data.forEach((data, index) => {
      statusData[data.id] = data.waiting;
    });
  }

  React.useEffect(() => {
    getJobs()
    getProfile()
  }, [session])

  const getJobs = async () => {
    try {
      setLoading(true)

      let { data: jobs, error, status } = await supabase
        .from('jobs')
        .select('*')
        .eq('status', '4')
        .eq('deleted', false)
        .order('finishedAt', { ascending: false })

      if (error && status !== 406) {
        throw error
      }
      if (jobs) {
        setJobs(jobs)
      }
    } catch (error) {
      setError(error)
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }
  const getProfile = async () => {
    let profilesData = [];
    try {
      setLoading(true)

      let { data, error, status } = await supabase
        .from('users')
        .select('*')

      if (error && status !== 406) {
        throw error
      }

      if (data) {
        data.forEach((data,index)=>
          {
            profilesData[data.id] = {email:data.email,name:data.full_name};
          }
        )
        setProfiles(profilesData)
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const navigate = useNavigate();
  const startJobRequest=(row,headers)=>{
    navigate('/running-job/'+row.id,{'state':{'session':session,'row':row}});
  }
  if (loading) {
    return (
      <div className="vy_requestedjobs is-loading">
        <Typography gutterBottom variant="h4">
          { titlePage }
        </Typography>
        <div className="vy_requestedjobs_form">
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableSkeleton numberCell='4' rowsPerPage={rowsPerPage} />
      
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
    </div>
    </div>
    );
  }

  if (error) {
    return(
      <div className="vy_requestedjobs is-error">
        <Typography gutterBottom variant="h4">
        { titlePage }
        </Typography>
        <div className="vy_requestedjobs_form">
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Failed to load { titlePage }
          </Alert>
        </div>
      </div>
    )
  }

  if (!error && !loading && jobs){
  return (
<Stack className="vy_requestedjobs" spacing={3}>
  <Typography variant="h4">
  { titlePage }
  </Typography>
  <div className="vy_requestedjobs_form">
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell
                  key={slugify(header)}
                  align="left"
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {jobs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row,row_key) => {
                  let columns = [];
                  // columns.push(row.id)
                  columns.push(row.requestedJob_productBatch)
                  columns.push(format(new Date(row.createdAt.replace('T', ' ')), 'MMM. dd, yyyy - H:mma'))
                  columns.push(format(new Date(row.finishedAt.replace('T', ' ')), 'MMM. dd, yyyy - H:mma'))
                  if(profiles && profiles[row.user_id]?.name){
                    columns.push(profiles[row.user_id].name)
                  }else if(profiles && profiles[row.user]?.email){
                    columns.push(profiles[row.user_id].email)
                  }

                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row_key} onClick={() => startJobRequest(row,headers)}>
                      {columns.map((column,column_key) => {
                        return (
                          <TableCell key={column_key} align="left">
                            {column === false ? 'False' : ''}
                            {column === true ? 'True' : column}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={jobs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>

  </div>
 </Stack>
  )
}
}

export default CompletedJobs