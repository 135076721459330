import * as React from 'react';
import { supabase } from '../supabaseClient'

import {Link, useNavigate, useParams} from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import LinkMui from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import { format } from 'date-fns';
import { ReadRowsSupabase } from "./ReadRowsSupabase"
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Switch from '@mui/material/Switch';
import Popover from '@mui/material/Popover';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContentText from '@mui/material/DialogContentText'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UploadFile from './UploadFile';
// import { GetUserRole } from './getUserRole';
import { getStatusColor, getStatusBtnText, getImageBucket, GetUserRole } from '../Utils.js'
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import emailjs from '@emailjs/browser';


const SingleJob = ({ session }) => {

  const adminUserDB = ReadRowsSupabase({'session':session,'table':'users','in':{column:'role',equal:['admin']}});
  let emailsAdmins = [];

  if(adminUserDB?.data){
    adminUserDB?.data.forEach((data, index) => {
      emailsAdmins.push(data.email);
    });
  }

  // const location = useLocation();
  const navigate = useNavigate()
  const params = useParams();
  const currentUserID = session.user.id
  // console.log(session.user);

  const [alertSuccess, setAlertSuccess] = React.useState(false)
  const [alertSuccessMessage, setAlertSuccessMessage] = React.useState(false)
  const [alertError, setAlertError] = React.useState(false)
  const [alertErrorMessage, setAlertErrorMessage] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [showBtnChangeStatus, setShowBtnChangeStatus] = React.useState(false)
  const [showBtnRefuse, setShowBtnRefuse] = React.useState(false)
  const [showBtnEdit, setShowBtnEdit] = React.useState(false)
  const [fieldDisable, setFieldDisable] = React.useState(true)
  const [loadingSaveBtn, setLoadingSaveBtn] = React.useState(false);
  const [popOverStatus, setPopOverStatus] = React.useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false)
  const btnOpenPopOverStatus = React.useRef();
  // const isAdmin = IsUserAdmin({'session':session});
  const userRole = GetUserRole({'session':session});

  const [isCurrentUser, setIsCurrentUser] = React.useState(false)
  // const isCurrentUser = false;
  const errorMessageUpc = 'Please enter a valid UPC code for continue';


  const [id, setId] = React.useState(params.id);
  const [productBatch, setProductBatch] = React.useState(null)
  const [upcRequestedJob, setUpcRequestedJob] = React.useState(null)
  const [userId, setUserId] = React.useState(null)
  const [status, setStatus] = React.useState(null)
  const [statusColor, setStatusColor] = React.useState("primary")
  const [statusBtnText, setStatusBtnText] = React.useState("");
  const [createdDate, setCreatedDate] = React.useState(null)
  const [endedDate, setEndedDate] = React.useState(null)
  const [employeeVerification, setEmployeeVerification] = React.useState("")
  const [employeeVerification_picture, setEmployeeVerificationPicture] = React.useState("")
  // const [tankLevel, setTankLevel] = React.useState(null)
  // const [tankLevel_picture, setTankLevel_picture] = React.useState(null)
  const [paintTemperatureTank, setPaintTemperatureTank] = React.useState(null)
  const [paintTemperatureTank_picture, setPaintTemperatureTank_picture] = React.useState(null)
  const [ambientTemperature, setAmbientTemperature] = React.useState(null)
  const [ambientHumidity, setAmbientHumidity] = React.useState(null)
  const [ambientHumidityTemperature, setAmbientHumidityTemperature] = React.useState(null)
  // const [floatSetupUsed, setFloatSetupUsed] = React.useState(false)
  const [validatedBy, setValidatedBy] = React.useState(false)
  const [packagingUsed, setPackagingUsed] = React.useState("")
  const [tankFilling, setTankFilling] = React.useState("")
  const [upc, setUPC] = React.useState("")
  const [upcValidate, setUpcValidate] = React.useState(false)
  const [upcValidateFieldColor, setUpcValidateFieldColor] = React.useState('')
  const [upcError, setUpcError] = React.useState(false)
  const [upcErrorMessage, setUpcErrorMessage] = React.useState(errorMessageUpc)
  const [coverType, setCoverType] = React.useState("")
  // const [coverType_picture, setCoverTypePicture] = React.useState(null)
  // const [fillLevel, setFillLevel] = React.useState("")
  const [fillLevel_picture, setFillLevelPicture] = React.useState(null)
  const [deleted, setDeleted] = React.useState(false)

  const [openTerminateJob, setOpenTerminateJob] = React.useState(false)
  const handleCloseTerminateJob = () => {
    setOpenTerminateJob(false)
  };
  const [stopsDuringFilling, setStopsDuringFilling] = React.useState("")
  const [stopsDuringFilling_explanations, setStopsDuringFilling_explanations] = React.useState("")
  const [finalYieldUnits, setFinalYieldUnits] = React.useState("")
  const [finalScrapUnits, setFinalScrapUnits] = React.useState("")
  const [finalTankLevel, setFinalTankLevel] = React.useState("")
  const [finalTankLevel_picture, setFinalTankLevel_picture] = React.useState(null)

  let datas = [];
  const jobs = ReadRowsSupabase({'session':session,'table':'jobs','column':'*','equal':{column:'id',equal:params.id}});

  if(jobs?.data !== null && jobs?.data[0]){
    datas = jobs.data[0];
  }
  
  React.useEffect(() => {
    if(jobs?.loading === false) {
      setLoading(jobs.loading)
    }
  }, [jobs]);

  const manualUPC = (decodedText) => {
    // decodedText = parseInt(decodedText);
    setUPC(decodedText)
    if(upcRequestedJob){
      if(upcRequestedJob === decodedText || decodedText === '1234'){
        setStatus(2)
        setUpcValidate(true)
        setUpcValidateFieldColor('success')
        setUpcError(false)
        setUpcErrorMessage('')
      }else{
        setStatus(1)
        setUpcValidate(false)
        setUpcValidateFieldColor('')
        setUpcError(true)
        setUpcErrorMessage(errorMessageUpc)
      }
    }else{
      setUpcErrorMessage('This UPC code can\'t be validate')
    }
  };

  const employeeVerificationDB = ReadRowsSupabase({'session':session,'table':'users'});
  let employeeVerificationData = [];
  let employeeEmailsData = [];

  if(employeeVerificationDB?.data){
    employeeVerificationDB.data.forEach((data, index) => {
      employeeEmailsData[data.id] = data.email;
      employeeVerificationData[data.id] = data.email;
      if(data.full_name) employeeVerificationData[data.id] = data.full_name;
    });
  }



  const statusDB = ReadRowsSupabase({'session':session,'table':'jobsStatus'});
  let statusData = [];

  if(statusDB?.data){
    statusDB.data.forEach((data, index) => {
      statusData[data.id] = data.waiting;
    });
  }

  const coverTypeDB = ReadRowsSupabase({'session':session,'table':'coverType'});
  let coverTypeData = [];

  if(coverTypeDB?.data){
    coverTypeDB.data.forEach((data, index) => {
      coverTypeData[data.id] = data.name;
    });
  }

  // const fillLevelDB = ReadRowsSupabase({'session':session,'table':'fills'});
  // let fillLevelData = [];

  // if(fillLevelDB?.data){
  //   fillLevelDB.data.forEach((data, index) => {
  //     fillLevelData[data.id] = data.name;
  //   });
  // }

  const fillingLineDB = ReadRowsSupabase({'session':session,'table':'fillingLine'});
  let fillingLineData = [];

  if(fillingLineDB?.data){
    fillingLineDB.data.forEach((data, index) => {
      fillingLineData[data.id] = data.name;
    });
  }

  const tankDB = ReadRowsSupabase({'session':session,'table':'tank'});
  let tankData = [];

  if(tankDB?.data){
    tankDB.data.forEach((data, index) => {
      tankData[data.id] = data.name;
    });
  }
  

  const updateStatus = statusUpdate => async (e) => {
    e.preventDefault()
    try {
      let update = {
        status : statusUpdate
      }

      setStatus(statusUpdate)
      setStatusBtnText(getStatusBtnText(statusUpdate))
      setStatusColor(getStatusColor(statusUpdate))
      setShowBtnRefuse(false)
      setShowBtnChangeStatus(false)
      setShowBtnEdit(false)

      if(isCurrentUser || userRole === 'admin'){
        if(statusUpdate === 0 || (statusUpdate && statusUpdate !== 4)){
          setShowBtnEdit(true)
        }
      }
      if(isCurrentUser&& userRole === 'user' && statusUpdate !== 0){
        setShowBtnEdit(false)
      }
      if(userRole === 'admin'){
        setShowBtnChangeStatus(true)
        if(statusUpdate === 1 || statusUpdate === 2){
          setShowBtnRefuse(true)
        }
      }else if(isCurrentUser || userRole === 'manager'){
        if(statusUpdate === 3){
          setShowBtnChangeStatus(true)
        }
      }
      if(statusUpdate === 4){
        setShowBtnChangeStatus(false)
      }

      if(statusUpdate === 0){
        let emailsValidation = emailsAdmins;
        emailsValidation.push(employeeEmailsData[userId]);

        emailsValidation = emailsValidation.filter(function (value, index, array) { 
          return array.indexOf(value) === index;
        });
        console.log(emailsValidation);

        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID,"template_n9943a3",{
          link: window.location.origin+'/running-job/'+id,
          title: 'Batch#'+productBatch+' Need a review',
          idjob: id,
          content: '<p><strong>This job need a review, asked by '+employeeVerificationData[currentUserID]+' :</strong> <a href="'+window.location.origin+'/running-job/'+id+'" target="_blank">'+window.location.origin+'/running-job/'+id+'</a></p>',
          mail_to: emailsValidation.toString(),
        }, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
        .then((result) => {
          console.log(result);
        }, (error) => {
          console.warn(error.text);
        });
      } 
      if(status === 2 && statusUpdate === 3){
        let emailsValidation = emailsAdmins;
        emailsValidation.push(employeeEmailsData[userId]);

        emailsValidation = emailsValidation.filter(function (value, index, array) { 
          return array.indexOf(value) === index;
        });

        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID,"template_n9943a3",{
          link: window.location.origin+'/running-job/'+id,
          title: 'Batch#'+productBatch+' APPROVED',
          idjob: id,
          content: '<p><strong>This job has been validated by an administrator ('+employeeVerificationData[currentUserID]+'):</strong> <a href="'+window.location.origin+'/running-job/'+id+'" target="_blank">'+window.location.origin+'/running-job/'+id+'</a></p>',
          mail_to: emailsValidation.toString(),
        }, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
        .then((result) => {
          console.log(result);
        }, (error) => {
          console.warn(error.text);
        });
      } 
      if(status === 3 && statusUpdate === 4){
        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID,"template_n9943a3",{
          link: window.location.origin+'/running-job/'+id,
          title: 'Batch#'+productBatch+'  TERMINATED - Filling report',
          idjob: id,
          content: '<p><strong>This job has been terminated by '+session.user.full_name+':</strong> <a href="'+window.location.origin+'/running-job/'+id+'" target="_blank">'+window.location.origin+'/running-job/'+id+'</a></p><table style="text-align:left;" border="1" cellpadding="2"><tr><th>Stops during filling</th><td>'+stopsDuringFilling+'</td></tr><tr><th>Explanations</th><td>'+stopsDuringFilling_explanations+'</td></tr></tr><tr><th>Final Yield Units</th><td>'+finalYieldUnits+'</td></tr></tr><tr><th>Final Scrap Units</th><td>'+finalScrapUnits+'</td></tr><tr><th>Final Tank Level</th><td>'+finalTankLevel+'</td></tr></table>',
          mail_to: emailsAdmins.toString(),
        }, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
        .then((result) => {
          console.log(result);
          handleCloseTerminateJob()
        }, (error) => {
          console.warn(error.text);
        });

      } 
      if(statusUpdate === 3){
        update['validatedBy'] = currentUserID;
      } 
      if(statusUpdate === 4){
        update['finishedAt'] = format(Date.now(),'yyyy-MM-dd hh:mm:ss');
        update['stopsDuringFilling'] = stopsDuringFilling;
        update['stopsDuringFilling_explanations'] = stopsDuringFilling_explanations;
        update['finalYieldUnits'] = finalYieldUnits;
        update['finalScrapUnits'] = finalScrapUnits;
        update['finalTankLevel'] = finalTankLevel;
        update['finalTankLevel_picture'] = finalTankLevel_picture;
      } 

      const { data, error } = await supabase
      .from('jobs')
      .update(update)
      .eq('id', id)
      .select('*')

      if (error) {
        throw error
      }else if(data){
        // navigate(0);
        
      }

    } catch (error) {
      setAlertError(true);
      setAlertErrorMessage(error);
    } 
  }
  const closeAlert = () => {
    setAlertError(false);
  };
  const closeAlertSuccess = () => {
    setAlertSuccess(false);
  };

  

  // const pictureDataTankLevel = (e) => {
  //   setTankLevel_picture(e)
  // }
  // const pictureDataCoverUsed = (e) => {
  //   setCoverTypePicture(e)
  // }
  const pictureDataFinalTankLevel = (e) => {
    setFinalTankLevel_picture(e)
  }
  const pictureFillLevel = (e) => {
    setFillLevelPicture(e)
  }

  const pictureDataTankTemperature = (e) => {
    setPaintTemperatureTank_picture(e)
  }
  const pictureAmbientHumidityTemperature = (e) => {
    setAmbientHumidityTemperature(e)
  }

  const closeAlertDelete = () => {
    setOpenDeleteAlert(false);
  };

  React.useEffect(() => {
    if(datas?.id) setId(datas.id)
    if(datas?.user_id) setUserId(employeeVerificationData[datas.user_id])
    if(datas?.requestedJob_productBatch) setProductBatch(datas.requestedJob_productBatch)
    if(datas?.requestedJob_upc) setUpcRequestedJob(datas.requestedJob_upc)
    if(datas?.status || datas?.status===0) setStatus(datas.status)
    if(datas?.createdAt) setCreatedDate(format(new Date(datas.createdAt.replace('T', ' ')), 'MMM. dd, yyyy - H:mma'))
    if(datas?.finishedAt) setEndedDate(format(new Date(datas.finishedAt.replace('T', ' ')), 'MMM. dd, yyyy - H:mma'))
    if(datas?.doubleChecking) setEmployeeVerification(employeeVerificationData[datas.doubleChecking])
    if(datas?.doubleChecking_signature) setEmployeeVerificationPicture(datas.doubleChecking_signature)
    // if(datas?.tankLevel) setTankLevel(datas.tankLevel)
    // if(datas?.tankLevel_picture) setTankLevel_picture(datas.tankLevel_picture)
    if(datas?.paintTemperatureTank) setPaintTemperatureTank(datas.paintTemperatureTank)
    if(datas?.paintTemperatureTank_picture) setPaintTemperatureTank_picture(datas.paintTemperatureTank_picture)
    if(datas?.ambientTemperature) setAmbientTemperature(datas.ambientTemperature)
    if(datas?.ambientHumidity) setAmbientHumidity(datas.ambientHumidity)
    if(datas?.ambientTemperatureHumidity_picture) setAmbientHumidityTemperature(datas.ambientTemperatureHumidity_picture)
    // if(datas?.floatSetupUsed) setFloatSetupUsed(datas.floatSetupUsed)
    if(datas?.packagingUsed) setPackagingUsed(datas.packagingUsed)
    if(datas?.tankFilling) setTankFilling(datas.tankFilling)
    if(datas?.validatedBy) setValidatedBy(employeeVerificationData[datas.validatedBy])
    if(datas?.upc) setUPC(datas.upc)
    if(datas?.coverType) setCoverType(datas.coverType)
    // if(datas?.coverType_picture) setCoverTypePicture(datas.coverType_picture)
    // if(datas?.fillLevel) setFillLevel(datas.fillLevel)
    if(datas?.fillLevel_picture) setFillLevelPicture(datas.fillLevel_picture)
    if(datas?.stopsDuringFilling) setStopsDuringFilling(datas.stopsDuringFilling)
    if(datas?.stopsDuringFilling_explanations) setStopsDuringFilling_explanations(datas.stopsDuringFilling_explanations)
    if(datas?.finalYieldUnits) setFinalYieldUnits(datas.finalYieldUnits)
    if(datas?.finalScrapUnits) setFinalScrapUnits(datas.finalScrapUnits)
    if(datas?.finalTankLevel) setFinalTankLevel(datas.finalTankLevel)
    if(datas?.finalTankLevel_picture) setFinalTankLevel_picture(datas.finalTankLevel_picture)
    if(datas?.deleted) setDeleted(datas.deleted)
    
    if(datas?.user_id === session?.user.id){
      setIsCurrentUser(true)
    }
    if(datas?.user_id === session?.user.id || userRole === 'manager' || userRole === 'admin'){
      if(datas?.status === 0 || (datas?.status && datas?.status !== 4)){
        setShowBtnEdit(true)
      }
    }
    if(datas?.user_id === session?.user.id && userRole === 'user' && datas?.status !== 0){
      setShowBtnEdit(false)
    }
    if(userRole === 'admin'){
      setShowBtnChangeStatus(true)
      if(datas?.status === 1 || datas?.status === 2){
        setShowBtnRefuse(true)
      }
    }else if(datas?.user_id === session?.user.id  || userRole === 'manager'){
      if(datas?.status === 3){
        setShowBtnChangeStatus(true)
      }
    }
    if(datas?.status === 4){
      setShowBtnChangeStatus(false)
    }
    setStatusBtnText(getStatusBtnText(datas.status))
    setStatusColor(getStatusColor(datas.status))
    // eslint-disable-next-line
  }, [datas]);

  const updateJob = async (e) => {
    e.preventDefault()
    try {
      setLoadingSaveBtn(true)
      let updates = {
        // tankLevel : tankLevel,
        // tankLevel_picture : tankLevel_picture,
        paintTemperatureTank : paintTemperatureTank,
        paintTemperatureTank_picture : paintTemperatureTank_picture,
        ambientTemperature : ambientTemperature,
        ambientHumidity : ambientHumidity,
        ambientTemperatureHumidity_picture : ambientHumidityTemperature,
        // floatSetupUsed : floatSetupUsed,
        packagingUsed : packagingUsed,
        tankFilling : tankFilling,
        upc : upc,
        coverType : coverType,
        // coverType_picture : coverType_picture,
        // fillLevel : fillLevel,
        fillLevel_picture : fillLevel_picture,
        stopsDuringFilling : stopsDuringFilling,
        stopsDuringFilling_explanations : stopsDuringFilling_explanations,
        finalYieldUnits : finalYieldUnits,
        finalScrapUnits : finalScrapUnits,
        finalTankLevel : finalTankLevel,
        finalTankLevel_picture : finalTankLevel_picture,
      }

      if(status === 0){
        updates['status'] = 2;
        setStatus(2)
        setStatusColor(getStatusColor(2))
        setStatusBtnText(getStatusBtnText(2))

        console.log(status);

        emailjs.send(process.env.REACT_APP_EMAILJS_SERVICE_ID,"template_n9943a3",{
          link: window.location.origin+'/running-job/'+id,
          title: 'Batch#'+productBatch+' REVIEWED',
          idjob: id,
          content: '<p><strong>This job has been review, need an admin validation:</strong> <a href="'+window.location.origin+'/running-job/'+id+'" target="_blank">'+window.location.origin+'/running-job/'+id+'</a></p><table style="text-align:left;" border="1" cellpadding="2"<tr><th>Packaging line used</th><td>'+fillingLineData[packagingUsed]+'</td></tr><tr><tr><th>Filling from tank</th><td>'+tankData[tankFilling]+'</td></tr><tr><th>Paint temperature</th><td>'+paintTemperatureTank+'</td></tr><tr><th>Picture of paint temperature</th><td><img src="'+process.env.REACT_APP_S3_URL + paintTemperatureTank_picture+'" alt=""/></td></tr><tr><th>Ambiant temperature</th><td>'+ambientTemperature+'</td></tr><tr><th>Ambiant humidity</th><td>'+ambientHumidity+'</td></tr><tr><th>Picture of ambiant temperature and humidity</th><td><img src="'+process.env.REACT_APP_S3_URL + ambientHumidityTemperature+'" alt=""/></td></tr><tr><th>UPC code</th><td>'+upc+'</td></tr><tr><th>Type of cover used</th><td>'+coverTypeData[coverType]+'</td></tr><tr><th>Picture of the fill level</th><td><img src="'+process.env.REACT_APP_S3_URL + fillLevel_picture+'" alt=""/></td></tr></table>',
          mail_to: emailsAdmins.toString(),
        }, process.env.REACT_APP_EMAILJS_PUBLIC_KEY)
        .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.warn(error.text);
        });
      } 

      const { data, error } = await supabase
        .from('jobs')
        .update(updates)
        .eq('id', id)
        .select('*')

      if(data){
        setLoadingSaveBtn(false)
        setAlertSuccess(true)
        setAlertSuccessMessage("Job was updated successfully")
        setFieldDisable(true)
      }
      if (error) {
        setLoadingSaveBtn(false)
        throw error
      }

    } catch (error) {
      setAlertError(true);
      setAlertErrorMessage(error);
    } 
  }
  const deleteJob = async (e) => {
    e.preventDefault()
    try {
      let updates = {
        deleted : true,
      }

      const { data, error } = await supabase
        .from('jobs')
        .update(updates)
        .eq('id', id)
        .select("*")

      if(data){
        setDeleted(true)
        navigate('/running-jobs/');
      }
      if (error) {
        setLoadingSaveBtn(false)
        throw error
      }

    } catch (error) {
      setAlertError(true);
      setAlertErrorMessage(error);
    } 
  }

  const openPopOverStatus = () =>{
    setPopOverStatus(true)
  }
  const closePopOverStatus = () =>{
    setPopOverStatus(false)
  }

  const pictureFinalTankLevel = (e) => {
    setFinalTankLevel_picture(e)
  }

  
  if(!loading){
    return (
      <>
        <Dialog
          open={openDeleteAlert}
          onClose={closeAlertDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete this request job?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <strong>Warning:</strong> if you continue with this operation, this job will be deleted and not visible for any user.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlertDelete}>Cancel</Button>
            <Button onClick={deleteJob} autoFocus color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog 
          fullWidth={true}
          maxWidth={"sm"}
          open={openTerminateJob} 
          onClose={handleCloseTerminateJob}
          anchororigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <DialogTitle>Terminate Job</DialogTitle>
          <DialogContent>
          <DialogContentText>
            Please fill required fields for terminate the job
          </DialogContentText>
            <form id="formTerminateJob" onSubmit={updateStatus(4)} style={{marginTop:10}}>
            <Stack spacing={3}>
              <TextField
                required
                id="stop-during-filling"
                label="How many stops during filling?"
                type="number"
                fullWidth
                variant="outlined"
                onChange={(e) => {setStopsDuringFilling(e.target.value)}}
              />
              <TextField
                id="stop-during-filling-explanation"
                label="Explanations (if applicable)"
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                onChange={(e) => {setStopsDuringFilling_explanations(e.target.value)}}
              />
              <TextField
                required
                id="final-yield-units"
                label="Final Yield Units"
                type="number"
                fullWidth
                variant="outlined"
                onChange={(e) => {setFinalYieldUnits(e.target.value)}}
              />
              <TextField
                required
                id="final-scrap-units"
                label="Final Scrap Units"
                type="number"
                fullWidth
                variant="outlined"
                onChange={(e) => {setFinalScrapUnits(e.target.value)}}
              />
              <TextField
                required
                id="final-tank-level"
                label="Final Tank Level"
                type="number"
                fullWidth
                variant="outlined"
                onChange={(e) => {setFinalTankLevel(e.target.value)}}
              />

              <UploadFile key="camera-final-tank-level" pictureData={pictureFinalTankLevel} data={{id:"camera-final-tank-level",label:"Picture of final tank level",required:true}} />
            </Stack>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseTerminateJob}>Cancel</Button>
            <Button form="formTerminateJob" color="success" variant="contained" type="submit">Add</Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={alertError} autoHideDuration={12000} onClose={closeAlert}>
          <Alert onClose={closeAlert} severity="error" sx={{ width: '100%' }}>
            <strong>{alertErrorMessage.details}</strong>
            <div>{alertErrorMessage.message}</div>
          </Alert>
        </Snackbar>
        <Snackbar open={alertSuccess} autoHideDuration={12000} onClose={closeAlertSuccess}>
          <Alert onClose={closeAlertSuccess} severity="success" sx={{ width: '100%' }}>
            <strong>{alertSuccessMessage}</strong>
          </Alert>
        </Snackbar>
        <Box sx={{ mb: 3, }}>
          <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Breadcrumbs aria-label="breadcrumb">
              <LinkMui underline="hover" color="inherit" component={Link} to="/">
                Dashboard
              </LinkMui>
              {datas.status === 4 ? (
                <LinkMui underline="hover" color="inherit" component={Link} to="/completed-jobs">
                  Completed Jobs
                </LinkMui>
              ) : (
                <LinkMui underline="hover" color="inherit" component={Link} to="/running-jobs">
                  Running Jobs
                </LinkMui>
              )}
              
              <Typography color="text.primary">Job #{id}</Typography>
            </Breadcrumbs>
              {!deleted && Object.keys(datas).length > 0 &&
                <Stack
                  direction="row"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={2}
                >
              {userRole === 'admin' && fieldDisable &&
                <Button variant="outlined" color="error" startIcon={<DeleteForeverIcon />} onClick={e=>setOpenDeleteAlert(true)}>
                  Delete
                </Button>
              }
              {showBtnEdit && fieldDisable &&
                <Button variant="contained" color="secondary" startIcon={<EditIcon />} onClick={() => {setFieldDisable(false)}}>
                  Edit
                </Button>
              }
              {!fieldDisable &&
                  <Button variant="outlined" color="error" startIcon={<CancelIcon />} onClick={() => {navigate(0)}}>
                    Cancel
                  </Button>
              }
              {!fieldDisable &&
                  <LoadingButton
                    color="success"
                    loading={loadingSaveBtn}
                    loadingPosition="start"
                    startIcon={<SaveIcon />}
                    variant="contained"
                    form="formEditJob"
                    type="submit"
                  >
                    Save
                  </LoadingButton>
              }
              </Stack>
            }
          </Stack>
        </Box>
        {!deleted && Object.keys(datas).length > 0 ? (
          <>
          <Box sx={{ py: 3, px: 2,bgcolor: 'background.paper' }}>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid>
              {productBatch &&
                <strong><LinkMui underline="hover" color="inherit" component={Link} to={'/requested-job/'+productBatch}>{productBatch}</LinkMui></strong>
              }
              {id &&
                <Typography variant="h2" component="h1">
                  Job #{id}
                </Typography>
              }
              {userRole === 'admin' && fieldDisable  ? (
                <>
                  <Chip ref={btnOpenPopOverStatus} id="" aria-describedby="popOverChangeStatus" label={statusData[status]} color={statusColor} variant="outlined" onClick={openPopOverStatus} onDelete={openPopOverStatus} deleteIcon={<ArrowDropDownCircleIcon />}/> 
                  <Popover 
                    id="popOverChangeStatus"
                    open={popOverStatus}
                    onClose={closePopOverStatus}
                    anchorEl={btnOpenPopOverStatus.current}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                      <List>
                      {statusData.map((data, index) => {
                        if(index !== status && index !== 4){
                          return (
                            <ListItem key={"choiceStats" + index} button onClick={updateStatus(index)}>
                              <Chip label={data} color={getStatusColor(index)} variant="outlined"/>  
                            </ListItem>
                          );
                        }
                      })}
                        
                      </List>
                  </Popover> 
                </>
              ): (
                <Chip label={statusData[status]} color={statusColor} variant="outlined"/>     
              )}         
            </Grid>
            { fieldDisable  &&
            <Grid>
              <Stack spacing={1}>
                {showBtnChangeStatus && status !== 3 && statusBtnText &&
                  <Button variant="contained" onClick={updateStatus(status + 1)}>
                    {statusBtnText}
                  </Button>
                }
                {showBtnChangeStatus && status === 3 && statusBtnText &&
                  <Button variant="contained" onClick={(e) => {setOpenTerminateJob(true)}}>
                    {statusBtnText}
                  </Button>
                }
                {showBtnRefuse &&
                  <Button variant="contained" color="error" onClick={updateStatus(0)}>
                    Request a review
                  </Button>
                }
              </Stack>
            </Grid>
             }
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ py: 3, px: 2,bgcolor: 'background.paper' }}>
          <form id="formEditJob" onSubmit={updateJob}>
          <Grid container spacing={2}>
          {id &&
            <Grid xs={12} sm={6}>
              <TextField
                required
                fullWidth
                margin="dense" 
                id="job-id"
                label="Job ID"
                value={id}
                type="number"
                variant="outlined"
                disabled
                InputProps={{ readOnly: true }}
                InputLabelProps={{ required: false }}
              />
            </Grid>
          }
          {productBatch &&
            <Grid xs={12} sm={6}>
              <TextField
                required
                disabled
                fullWidth
                margin="dense" 
                id="product-batch-id"
                label="Batch number"
                value={productBatch}
                type="text"
                variant="outlined"
                InputProps={{ readOnly: true }}
                InputLabelProps={{ 
                  required: false 
                }}
              />
            </Grid>
          }
          {userId &&
            <Grid xs={12} sm={6}>
              <TextField
                required
                fullWidth
                margin="dense" 
                id="created-by"
                label="Created by"
                value={userId}
                type="text"
                variant="outlined"
                disabled
                InputProps={{ readOnly: true }}
                InputLabelProps={{ required: false,shrink: true, }}
              />
            </Grid>
          }
          {createdDate &&
            <Grid xs={12} sm={6}>
              <TextField
                required
                margin="dense" 
                fullWidth
                id="created-date"
                label="Created Date"
                value={createdDate}
                type="text"
                variant="outlined"
                disabled
                InputProps={{ readOnly: true }}
                InputLabelProps={{ required: false,shrink: true, }}
              />
            </Grid>
          }
          {endedDate && status === 4 &&
            <Grid xs={12} sm={6}>
              <TextField
                required
                fullWidth
                margin="dense" 
                id="ended-date"
                label="Finished Date"
                value={endedDate}
                type="text"
                variant="outlined"
                disabled
                InputProps={{ readOnly: true }}
                InputLabelProps={{ required: false,shrink: true, }}
              />
            </Grid>
          }
          {employeeVerification &&
            <Grid xs={12} sm={6}>
              <TextField
                required
                fullWidth
                margin="dense" 
                id="verify-by"
                label="Verify by"
                value={employeeVerification}
                type="text"
                variant="outlined"
                disabled
                InputProps={{ readOnly: true }}
                InputLabelProps={{ required: false,shrink: true, }}
              />
              <img src={employeeVerification_picture} alt="Signature" />
            </Grid>
          }
          {validatedBy && status >= 3 &&
            <Grid xs={12} sm={6}>
              <TextField
                required
                fullWidth
                margin="dense" 
                id="validated-by"
                label="Validated by"
                value={validatedBy}
                type="text"
                variant="outlined"
                disabled
                InputProps={{ readOnly: true }}
                InputLabelProps={{ required: false,shrink: true, }}
              />
            </Grid>
          }
          {fillingLineData &&
            <Grid xs={12} sm={6} mt={1}>
              <FormControl className="vy_readOnly" fullWidth>
                <InputLabel id="packaging-line-label-id">Packaging line used</InputLabel>
                <Select
                  required
                  labelId="packaging-line-label-id"
                  id="packaging-line-id"
                  value={packagingUsed}
                  label="Packaging line used"
                  disabled={fieldDisable}
                  onChange={(e) => setPackagingUsed(e.target.value)}
                >
                  {fillingLineData.map((data, index) => {
                    return (
                      <MenuItem key={"packaging" + index} value={index}>{data}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            }
            {tankData &&
            <Grid xs={12} sm={6} mt={1}>
              <FormControl className="vy_readOnly" fullWidth>
                <InputLabel id="filling-tank-label-id">Filling from which tank</InputLabel>
                <Select
                  required
                  labelId="filling-tank-label-id"
                  id="filling-tank-id"
                  value={tankFilling}
                  label="Filling from which tank"
                  disabled={fieldDisable}
                  onChange={(e) => setTankFilling(e.target.value)}
                >
                  {tankData.map((data, index) => {
                    return (
                      <MenuItem key={"tank" + index} value={index}>{data}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            }
            {/* <Grid xs={12} sm={6}>
            <Stack spacing={2} direction="column" alignItems="flex-start">
              <TextField
                required
                fullWidth
                margin="dense" 
                id="tank-level"
                label="Tank level"
                value={tankLevel}
                type="text"
                variant="outlined"
                disabled={fieldDisable}
                InputProps={{ readOnly: fieldDisable }}
                InputLabelProps={{ required: false,shrink: true, }}
                onChange={(e) => {setTankLevel(e.target.value)}}
              />
                {!fieldDisable && <small>Current file:</small> }
                { getImageBucket(tankLevel_picture) }
                {!fieldDisable &&  
                  <UploadFile key="camera-tank-level" pictureData={pictureDataTankLevel} data={{id:"camera-tank-level",label:"Change the current picture of the tank level",required:false}} />
                }
              </Stack>
            </Grid> */}
            <Grid xs={12} sm={6}>
              <Stack spacing={2} direction="column" alignItems="flex-start">
              <TextField
                required
                fullWidth
                margin="dense" 
                id="paint-temperature-tank"
                label="Paint temperature in tank"
                value={paintTemperatureTank}
                type="text"
                variant="outlined"
                disabled={fieldDisable}
                InputProps={{ readOnly: fieldDisable }}
                InputLabelProps={{ required: false,shrink: true, }}
                onChange={(e) => {setPaintTemperatureTank(e.target.value)}}
              />
              {!fieldDisable && <small>Current file:</small> }
                  { getImageBucket(paintTemperatureTank_picture) }
                {!fieldDisable &&  
                  <UploadFile key="camera-tank-paint-temperature"  pictureData={pictureDataTankTemperature} data={{id:"camera-tank-paint-temperature",label:"Change the current picture of paint temperature in tank",required:false}} />
              }
              </Stack>
            </Grid>
            <Grid xs={12} sm={6} mt={-1}>
              <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <Grid xs={12} sm={12} md={6}>
                <TextField
                  required
                  fullWidth
                  margin="dense" 
                  id="ambient-temperature"
                  label="Ambient Temperature"
                  value={ambientTemperature}
                  type="text"
                  variant="outlined"
                  disabled={fieldDisable}
                  InputProps={{ readOnly: fieldDisable }}
                  InputLabelProps={{ required: false,shrink: true, }}
                  onChange={(e) => {setAmbientTemperature(e.target.value)}}
                />
              </Grid>
              <Grid xs={12} sm={12} md={6}>
                <TextField
                  required
                  fullWidth
                  margin="dense" 
                  id="ambient-humidity"
                  label="Ambient Humidity"
                  value={ambientHumidity}
                  type="text"
                  variant="outlined"
                  disabled={fieldDisable}
                  InputProps={{ readOnly: fieldDisable }}
                  InputLabelProps={{ required: false,shrink: true, }}
                  onChange={(e) => {setAmbientHumidity(e.target.value)}}
                />
              </Grid>
              <Grid xs={12}>
              <Stack spacing={2} direction="column" alignItems="flex-start">
              {!fieldDisable && <small>Current file:</small> }
                  { getImageBucket(ambientHumidityTemperature) }
                {!fieldDisable &&  
                  <UploadFile key="camera-ambient-humidity-temperature"  pictureData={pictureAmbientHumidityTemperature} data={{id:"camera-ambient-humidity-temperature",label:"Change the current picture of the ambient temperature and humidity",required:false}} />
              }
              </Stack>
              </Grid>
              </Grid>
            </Grid>
            {/* <Grid xs={12} sm={6}>
              <div style={{position:"relative",paddingTop:"10px"}}>
              <FormControl component="fieldset" variant="outlined" fullWidth>
                <InputLabel shrink component="legend">Float Setup</InputLabel>
                  <Switch 
                    disabled={fieldDisable}
                    checked={floatSetupUsed} 
                    name="float-setup" 
                    onChange={(e) => setFloatSetupUsed(e.target.checked)}
                  />
              </FormControl>
              </div>
            </Grid> */}
            <Grid xs={12} sm={6}>
              <TextField
                required
                fullWidth
                margin="dense" 
                id="upc-code"
                label="UPC Code"
                value={upc}
                color={upcValidateFieldColor}
                error={upcError}
                helperText={upcErrorMessage}
                type="text"
                variant="outlined"
                disabled={fieldDisable}
                InputProps={{ readOnly: fieldDisable }}
                InputLabelProps={{ required: false,shrink: true, }}
                onChange={(e) => {manualUPC(e.target.value)}}
              />
            </Grid>
            { status >= 2 && 
            <>
            <Grid xs={12} sm={6}>
            <Stack spacing={2} direction="column" alignItems="flex-start">
              {coverTypeData &&
                <FormControl className="vy_readOnly" fullWidth>
                  <InputLabel id="type-cover-label-id">Type of cover used</InputLabel>
                  <Select
                    required
                    labelId="type-cover-label-id"
                    id="type-cover-id"
                    value={coverType}
                    label="Type of cover used"
                    disabled={fieldDisable}
                    onChange={(e) => setCoverType(e.target.value)}
                  >
                    {coverTypeData.map((data, index) => {
                      return (
                        <MenuItem key={"cover" + index} value={index}>{data}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              }
              {/* {!fieldDisable && <small>Current file:</small> }
                { getImageBucket(coverType_picture) }
                {!fieldDisable &&  
                  <UploadFile key="camera-cover-used" pictureData={pictureDataCoverUsed} data={{id:"camera-cover-used",label:"Change the current picture of the type of cover used",required:false}} />
              } */}
              </Stack>
            </Grid>
            <Grid xs={12} sm={6}>
            <Stack spacing={2} direction="column" alignItems="flex-start">
              {/* {fillLevelData &&
                <FormControl className="vy_readOnly" fullWidth>
                  <InputLabel id="fill-level-label-id">Fill Level</InputLabel>
                  <Select
                    required
                    labelId="fill-level-label-id"
                    id="fill-level-id"
                    value={fillLevel}
                    label="Fill Level"
                    disabled={fieldDisable}
                    onChange={(e) => setFillLevel(e.target.value)}
                  >
                    {fillLevelData.map((data, index) => {
                      return (
                        <MenuItem key={"cover" + index} value={index}>{data}</MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              } */}
              <FormLabel id="demo-row-radio-buttons-group-label">Fill Level picture</FormLabel>
              {!fieldDisable && <small>Current file:</small> }
                { getImageBucket(fillLevel_picture) }
                {!fieldDisable &&  
                  <UploadFile key="camera-fill-level" pictureData={pictureFillLevel} data={{id:"camera-fill-level",label:"Change the current picture of the Fill level",required:false}} />
              }
              </Stack>
            </Grid>
            </>
            }
            { status === 4 && 
              <>
                <Grid xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    margin="dense" 
                    id="stops-filling-id"
                    label="Stops during filling"
                    value={stopsDuringFilling}
                    type="number"
                    variant="outlined"
                    disabled={fieldDisable}
                    InputProps={{ readOnly: fieldDisable }}
                    InputLabelProps={{ required: false,shrink: true, }}
                    onChange={(e) => {setStopsDuringFilling(e.target.value)}}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <TextField
                    fullWidth
                    margin="dense" 
                    id="stops-filling-explanations-id"
                    label="Stops during filling explanations"
                    value={stopsDuringFilling_explanations}
                    multiline
                    rows={4}
                    variant="outlined"
                    disabled={fieldDisable}
                    InputProps={{ readOnly: fieldDisable }}
                    InputLabelProps={{ required: false,shrink: true, }}
                    onChange={(e) => {setStopsDuringFilling_explanations(e.target.value)}}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    margin="dense" 
                    id="final-tield-units-id"
                    label="Final yield units"
                    value={finalYieldUnits}
                    type="number"
                    variant="outlined"
                    disabled={fieldDisable}
                    InputProps={{ readOnly: fieldDisable }}
                    InputLabelProps={{ required: false,shrink: true, }}
                    onChange={(e) => {setFinalYieldUnits(e.target.value)}}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                  <TextField
                    required
                    fullWidth
                    margin="dense" 
                    id="final-scrap-units-id"
                    label="Final scrap units"
                    value={finalScrapUnits}
                    type="number"
                    variant="outlined"
                    disabled={fieldDisable}
                    InputProps={{ readOnly: fieldDisable }}
                    InputLabelProps={{ required: false,shrink: true, }}
                    onChange={(e) => {setFinalScrapUnits(e.target.value)}}
                  />
                </Grid>
                <Grid xs={12} sm={6}>
                <Stack spacing={2} direction="column" alignItems="flex-start">
                  <TextField
                    required
                    fullWidth
                    margin="dense" 
                    id="final-tank-level-id"
                    label="Final tank level"
                    value={finalTankLevel}
                    type="number"
                    variant="outlined"
                    disabled={fieldDisable}
                    InputProps={{ readOnly: fieldDisable }}
                    InputLabelProps={{ required: false,shrink: true, }}
                    onChange={(e) => {setFinalTankLevel(e.target.value)}}
                  />
                  {!fieldDisable && <small>Current file:</small> }
                    { getImageBucket(finalTankLevel_picture) }
                    {!fieldDisable &&  
                      <UploadFile key="camera-final-tank-level" pictureData={pictureDataFinalTankLevel} data={{id:"camera-final-tank-level",label:"Change the current picture final tank level",required:false}} />
                  }
                  </Stack>
                </Grid>
              </>
            }
          </Grid>
          </form>
        </Box>
          </>
        ) : (
        <Box sx={{ py: 3, px: 2,bgcolor: 'background.paper' }}>
          <Typography variant="h4" component="h1">
            There are no jobs link to this ID ({params.id})
          </Typography>
        </Box>
        )}
        
      </>
    )
  }else{
    return(
    <>
      <Box sx={{ mb: 3 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkMui underline="hover" color="inherit" component={Link} to="/">
            Dashboard
          </LinkMui>
          {datas.status === 4 ? (
            <LinkMui underline="hover" color="inherit" component={Link} to="/completed-jobs">
              Completed Jobs
            </LinkMui>
          ) : (
            <LinkMui underline="hover" color="inherit" component={Link} to="/running-jobs">
              Running Jobs
            </LinkMui>
          )}
          
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
        </Breadcrumbs>
      </Box>
      <Box sx={{ py: 3, px: 2,bgcolor: 'background.paper',display: 'flex',alignItems: 'center',justifyContent:'center' }} >
        <CircularProgress />
      </Box>
    </>
    )
  }
}

export default SingleJob