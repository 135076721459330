import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Skeleton from '@mui/material/Skeleton';

const TableSkeleton = ({numberCell,rowsPerPage}) => {
  return(
    <TableContainer sx={{ maxHeight: 600 }}>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {Array(Number(numberCell)).fill(null).map((value, index) => (
              <TableCell key={'skeletonheader' + index}>
                <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array(Number(rowsPerPage)).fill(null).map((value, index) => (
            <TableRow key={'skeletonrow' + index} role="checkbox">
              {Array(Number(numberCell)).fill(null).map((value, index) => (
                <TableCell key={'skeletonrowcell' + index} align="left">
                  <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export default TableSkeleton