import * as React from 'react';
import { supabase } from '../supabaseClient'

import {Link, useParams, useNavigate} from 'react-router-dom';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import LinkMui from '@mui/material/Link';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddJobs from './AddJobs'
import { GetUserRole } from '../Utils.js'
import { format } from 'date-fns';
import { ReadRowsSupabase } from "./ReadRowsSupabase"
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const SingleRequestedJobs = ({ session }) => {
  const params = useParams();
  const navigate = useNavigate()

  const [loading, setLoading] = React.useState(true)
  // const userRole = session.user.user_role;
  const userRole = GetUserRole({'session':session});
  
  const [showBtnEdit, setShowBtnEdit] = React.useState(false)
  const [fieldDisable, setFieldDisable] = React.useState(true)
  const [loadingSaveBtn, setLoadingSaveBtn] = React.useState(false);
  const [alertSuccess, setAlertSuccess] = React.useState(false)
  const [alertSuccessMessage, setAlertSuccessMessage] = React.useState(false)
  const [alertError, setAlertError] = React.useState(false)
  const [alertErrorMessage, setAlertErrorMessage] = React.useState(false)
  const [openDeleteAlert, setOpenDeleteAlert] = React.useState(false)

  const [id, setID] = React.useState(params.id)
  const [packagingOrder, setpackagingOrder] = React.useState(null)
  const [batchSize, setBatchSize] = React.useState(null)
  const [requestedDate, setRequestedDate] = React.useState(null)
  const [formatField, setFormatField] = React.useState("")
  const [orderedQuantity, setOrderedQuantity] = React.useState("")
  // const [floatRequired, setFloatRequired] = React.useState(false)
  const [coverTypeNeeded, setCoverTypeNeeded] = React.useState(null)
  // const [fillingLine, setFillingLine] = React.useState(null)
  const [labelNumber, setLabelNumber] = React.useState(null)
  const [labelUPC, setLabelUPC] = React.useState(null)
  const [labelUPCMessage, setLabelUPCMessage] = React.useState('Choose a valid combination for Volume and Product for filling the label UPC field')
  const [deleted, setDeleted] = React.useState(false)
  
  

  const employeeVerificationDB = ReadRowsSupabase({'session':session,'table':'users','in':{column:'role',equal:['admin','manager']}});
  let employeeVerificationData = [];

  if(employeeVerificationDB?.data){
    employeeVerificationData = employeeVerificationDB.data;
  }


  let datas = [];

  const requestedJob = ReadRowsSupabase({'session':session,'table':'requestedJobs','column':'*','equal':{column:'id',equal:id}});

  if(requestedJob?.data !== null && requestedJob?.data[0]){
    datas = requestedJob.data[0];
  }

  React.useEffect(() => {
    if(userRole === 'admin' || userRole === 'manager') setShowBtnEdit(true);
    // if(userRole === 'admin') setShowBtnEdit(true);
  }, [userRole]);

  React.useEffect(() => {    
    if(requestedJob?.loading === false) {
      setLoading(requestedJob.loading)
    }
    // if(datas?.id) setID(datas.id)
    if(datas?.packagingOrder) setpackagingOrder(datas.packagingOrder)
    if(datas?.batchSize) setBatchSize(datas.batchSize)
    if(datas?.requestedDate) setRequestedDate(datas.requestedDate)
    if(datas?.format) setFormatField(datas.format)
    if(datas?.orderedQuantity) setOrderedQuantity(datas.orderedQuantity)
    // if(datas?.floatRequired) setFloatRequired(datas.floatRequired)
    if(datas?.coverType) setCoverTypeNeeded(datas.coverType)
    // if(datas?.fillingLine) setFillingLine(datas.fillingLine)
    if(datas?.labelNumber) setLabelNumber(datas.labelNumber)
    if(datas?.deleted) setDeleted(datas.deleted)
    if(datas?.labelUPC) setLabelUPC(datas.labelUPC)

  }, [datas]);

  const itemNumberUpc = ReadRowsSupabase({'session':session,'table':'upc'});
  let itemNumberUpcData = [];
  let modelNumberData = [];
  let modelNumberAutocomplete = [];

  if(itemNumberUpc?.data){
    itemNumberUpc.data.forEach((data, index) => {
      itemNumberUpcData[data.itemNumber] = data.fullUPC;
      // modelNumberData[data.modelNumber] = data.modelNumber;
      modelNumberData.push(data.modelNumber);
    });
    if(modelNumberData){
      modelNumberData = modelNumberData.filter(function (value, index, array) { 
        return array.indexOf(value) === index;
      });
      modelNumberData.forEach((data, index) => {
        modelNumberAutocomplete.push({ label: data })
      });
    }
  }

  const createLabelUpc = (formatValue,labelNumberValue) => {
    // console.log(labelNumberValue);
    const itemNumber = labelNumberValue + formatSuffixData[formatValue];
    if(itemNumberUpcData[itemNumber]){
      setLabelUPC(itemNumberUpcData[itemNumber])
      setLabelUPCMessage("")
    }else{
      setLabelUPC("")
      setLabelUPCMessage("There's no matching UPC for the product with this format. Please review those fields")
    }
  };

  // const fillingLineDB = ReadRowsSupabase({'session':session,'table':'fillingLine'});
  // let fillingLineData = [];

  // if(fillingLineDB?.data){
  //   fillingLineDB.data.forEach((data, index) => {
  //     fillingLineData[data.id] = data.name;
  //   });
  // }


  const coverTypeDB = ReadRowsSupabase({'session':session,'table':'coverType'});
  let coverTypeData = [];

  if(coverTypeDB?.data){
    coverTypeDB.data.forEach((data, index) => {
      coverTypeData[data.id] = data.name;
    });
  }

  const formatDB = ReadRowsSupabase({'session':session,'table':'format'});
  let formatData = [];
  let formatSuffixData = [];

  if(formatDB?.data){
    formatDB.data.forEach((data, index) => {
      formatData[data.id] = data.suffix + ' - ' + data.name;
      formatSuffixData[data.id] = data.suffix;
    });
  }

  const batchSizeDB = ReadRowsSupabase({'session':session,'table':'batchSizes'});
  let batchSizeData = [];

  if(batchSizeDB?.data){
    batchSizeDB.data.forEach((data, index) => {
      batchSizeData[data.id] = data.name;
    });
  }

  const updateRequestedJobs = async (e) => {
    e.preventDefault()

    try {
      setLoadingSaveBtn(true)
      let updates = {
        packagingOrder : packagingOrder,
        batchSize : batchSize,
        requestedDate : requestedDate,
        format : formatField,
        orderedQuantity : orderedQuantity,
        // floatRequired : floatRequired,
        coverType : coverTypeNeeded,
        // fillingLine : fillingLine,
        labelNumber : labelNumber,
        labelUPC : labelUPC,
      }

      const { data, error } = await supabase
        .from('requestedJobs')
        .update(updates)
        .eq('id', id)
        .select('*')

      if(data){
        setLoadingSaveBtn(false)
        setAlertSuccess(true)
        setAlertSuccessMessage("Job was updated successfully")
        setFieldDisable(true)
      }
      if (error) {
        setLoadingSaveBtn(false)
        throw error
      }

    } catch (error) {
      setAlertError(true);
      setAlertErrorMessage(error);
    } 
  }
  const deleteRequestedJobs = async (e) => {
    e.preventDefault()
    try {
      let updates = {
        deleted : true,
      }

      const { data, error } = await supabase
        .from('requestedJobs')
        .update(updates)
        .eq('packagingOrder', packagingOrder)
        .select("*")

      if(data){
        setDeleted(true)
        navigate('/requested-jobs/');
      }
      if (error) {
        setLoadingSaveBtn(false)
        throw error
      }

    } catch (error) {
      setAlertError(true);
      setAlertErrorMessage(error);
    } 
  }

  const closeAlert = () => {
    setAlertError(false);
  };
  const closeAlertSuccess = () => {
    setAlertSuccess(false);
  };

  const closeAlertDelete = () => {
    setOpenDeleteAlert(false);
  };

  if(!loading){
    return (
      <>
        <Dialog
          open={openDeleteAlert}
          onClose={closeAlertDelete}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Delete this request job?"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <strong>Warning:</strong> if you continue with this operation, this requested job will be deleted and not visible for any user.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAlertDelete}>Cancel</Button>
            <Button onClick={deleteRequestedJobs} autoFocus color="error">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar open={alertError} autoHideDuration={12000} onClose={closeAlert}>
          <Alert onClose={closeAlert} severity="error" sx={{ width: '100%' }}>
            <strong>{alertErrorMessage.details}</strong>
            <div>{alertErrorMessage.message}</div>
          </Alert>
        </Snackbar>
        <Snackbar open={alertSuccess} autoHideDuration={12000} onClose={closeAlertSuccess}>
          <Alert onClose={closeAlertSuccess} severity="success" sx={{ width: '100%' }}>
            <strong>{alertSuccessMessage}</strong>
          </Alert>
        </Snackbar>
        <Box sx={{ mb: 3 }}>
        <Stack
            direction={{ xs: 'column', sm: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
          <Breadcrumbs aria-label="breadcrumb">
            <LinkMui underline="hover" color="inherit" component={Link} to="/">
              Dashboard
            </LinkMui>
            <LinkMui underline="hover" color="inherit" component={Link} to="/requested-jobs">
              Requested Jobs
            </LinkMui>
            <Typography color="text.primary">{packagingOrder}</Typography>
          </Breadcrumbs>

          {!deleted && Object.keys(datas).length > 0 &&
          <Stack
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
            spacing={2}
          >
            {userRole === 'admin' && fieldDisable && !datas.isRunning &&
              <Button variant="outlined" color="error" startIcon={<DeleteForeverIcon />} onClick={e=>setOpenDeleteAlert(true)}>
                Delete
              </Button>
            }
            {showBtnEdit && fieldDisable && !datas.isRunning &&
              <Button variant="contained" color="secondary" startIcon={<EditIcon />} onClick={() => {setFieldDisable(false)}}>
                Edit
              </Button>
            }
            {!fieldDisable && !datas.isRunning &&
              <Button variant="outlined" color="error" startIcon={<CancelIcon />} onClick={() => {navigate(0)}}>
                Cancel
              </Button>
            }
            {!fieldDisable &&
              <LoadingButton
                color="success"
                loading={loadingSaveBtn}
                loadingPosition="start"
                startIcon={<SaveIcon />}
                variant="contained"
                form="formEditRequestedJob"
                type="submit"
              >
                Save
              </LoadingButton>
            }
            </Stack>
          }
            </Stack>
        </Box>
        {!deleted && Object.keys(datas).length > 0 ? (
          <>
        <Box sx={{ py: 3, px: 2,bgcolor: 'background.paper' }}>
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid>
              <strong>{labelNumber}</strong>
              <Typography variant="h2" component="h1">
                {packagingOrder}
              </Typography>
              {/* <p>{fillingLineData[fillingLine]}</p> */}
            </Grid>
            <Grid>
            {!deleted ? (
              <>
                {!datas.isRunning ? 
                  (
                    <AddJobs session={session} coverType={coverTypeNeeded}  idRequestedJob={id} UPCRequestedJob={labelUPC} ProductBatchRequestedJob={packagingOrder} employeeVerificationData={employeeVerificationData} />
                  ) : (
                    <Chip label="Already associate to a job" color="default" variant="outlined"/>
                  )
                }
              </>
            ): (
              <>
                <Chip label="Request Job Deleted" color="error" variant="outlined"/>
              </>
            )}
            </Grid>
          </Grid>
        </Box>
        <Divider />
        <Box sx={{ py: 3, px: 2,bgcolor: 'background.paper' }}>
        <form id="formEditRequestedJob" onSubmit={updateRequestedJobs}>
          <Grid container spacing={2}>
          <Grid xs={12} sm={6}>
            <TextField
              required
              fullWidth
              margin="dense" 
              id="product-batch"
              label="Batch number"
              value={packagingOrder}
              type="text"
              variant="outlined"
              onChange={(e) => setpackagingOrder(e.target.value)}
              disabled={(userRole === 'admin' )?fieldDisable:true}
              InputProps={(userRole === 'admin' )?{ readOnly: fieldDisable }:{ readOnly: true }}
              InputLabelProps={{ required: false,shrink: true }}
            />
          </Grid>
          <Grid xs={12} sm={6}>
            <FormControl 
                className="vy_readOnly"
                style={{marginTop:'8px'}} fullWidth>
              <InputLabel id="batch-size-label-id">Batch size</InputLabel>
              <Select
                required
                fullWidth
                margin="dense" 
                labelId="batch-size-label-id"
                id="batch-size-id"
                value={batchSize}
                label="Batch size"
                disabled={(userRole === 'admin' )?fieldDisable:true}
                onChange={(e) => {setBatchSize(e.target.value);}}
              >
                {batchSizeData.map((data, index) => {
                  return (
                    <MenuItem key={"batch-size" + index} value={index}>{data}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {fieldDisable === false ?
            (
              <Grid xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  margin="dense" 
                  id="date-requested"
                  label="Date requested"
                  value={format(new Date(requestedDate.replace(/-/g, '/')), 'yyyy-MM-dd')}
                  type="date"
                  variant="outlined"
                  onChange={(e) => setRequestedDate(e.target.value)}
                  InputLabelProps={{ required: false,shrink: true }}
                />
              </Grid>
            ):(
              <Grid xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  margin="dense" 
                  id="date-requested"
                  label="Date requested"
                  value={format(new Date(requestedDate.replace(/-/g, '/')), 'MMM. dd, yyyy')}
                  type="text"
                  variant="outlined"
                  disabled
                  InputProps={{ readOnly: true }}
                  InputLabelProps={{ required: false,shrink: true }}
                />
              </Grid>
            )
          }
          
          <Grid xs={12} sm={6}>
            <FormControl 
                className="vy_readOnly"
                style={{marginTop:'8px'}} fullWidth>
              <InputLabel id="format-label-id">Volume</InputLabel>
              <Select
                required
                fullWidth
                margin="dense" 
                labelId="format-label-id"
                id="format-id"
                value={formatField}
                label="Volume"
                disabled={(userRole === 'admin' )?fieldDisable:true}
                onChange={(e) => {setFormatField(e.target.value);createLabelUpc(e.target.value,labelNumber);}}
              >
                {formatData.map((data, index) => {
                  return (
                    <MenuItem key={"format" + index} value={index}>{data}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={12} sm={6}>
            <TextField
              required
              fullWidth
              margin="dense" 
              id="ordered-quantity"
              label="Ordered quantity"
              value={orderedQuantity}
              type="number"
              variant="outlined"
              disabled={(userRole === 'admin' )?fieldDisable:true}
              InputProps={(userRole === 'admin' )?{ readOnly: fieldDisable }:{ readOnly: true }}
              InputLabelProps={{ required: false,shrink: true }}
              onChange={(e) => {setOrderedQuantity(e.target.value)}}
            />
          </Grid>
          {/* <Grid xs={12} sm={6}>
            <div style={{position:"relative",paddingTop:"10px"}}>
            <FormControl component="fieldset" variant="outlined" fullWidth>
              <InputLabel shrink component="legend">Float required</InputLabel>
                <Switch 
                  disabled={(userRole === 'admin' )?fieldDisable:true}
                  checked={floatRequired} 
                  name="float-required" 
                  onChange={(e) => setFloatRequired(e.target.checked)}
                />
            </FormControl>
            </div>
          </Grid> */}
          <Grid xs={12} sm={6}>
            <FormControl 
                className="vy_readOnly"
                style={{marginTop:'8px'}} fullWidth>
              <InputLabel id="cover-type-label-id">Cover type needed</InputLabel>
              <Select
                required
                fullWidth
                margin="dense" 
                labelId="cover-type-label-id"
                id="cover-type-id"
                value={coverTypeNeeded}
                label="Cover type needed"
                disabled={(userRole === 'admin' )?fieldDisable:true}
                onChange={(e) => setCoverTypeNeeded(e.target.value)}
              >
                {coverTypeData.map((data, index) => {
                  return (
                    <MenuItem key={"cover" + index} value={index}>{data}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          {/* <Grid xs={12} sm={6}>
            <FormControl 
                className="vy_readOnly"
                style={{marginTop:'8px'}} fullWidth>
              <InputLabel id="filling-line-label-id">Filling line</InputLabel>
              <Select
                required
                fullWidth
                margin="dense" 
                labelId="filling-line-label-id"
                id="filling-line-id"
                value={fillingLine}
                label="Filling line"
                disabled={fieldDisable}
                onChange={(e) => setFillingLine(e.target.value)}
              >
                {fillingLineData.map((data, index) => {
                  return (
                    <MenuItem key={"filling" + index} value={index}>{data}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid> */}
            <Grid xs={12} sm={6}>
              {userRole === 'admin' && fieldDisable ? (
                <TextField
                  required
                  fullWidth
                  margin="dense" 
                  id="model-number"
                  label="Product"
                  value={labelNumber}
                  type="text"
                  variant="outlined"
                  disabled={(userRole === 'admin' )?fieldDisable:true}
                  InputProps={(userRole === 'admin' )?{ readOnly: fieldDisable }:{ readOnly: true }}
                  InputLabelProps={{ required: false,shrink: true }}
                  onChange={(e) => {setLabelNumber(e.target.value)}}
                />
                ):(
                  <>
                  {modelNumberAutocomplete ?
                    (
                      <Autocomplete
                        disablePortal
                        id="model-number"
                        value={labelNumber}
                        disabled={(userRole === 'admin' )?fieldDisable:true}
                        
                        options={modelNumberAutocomplete}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(e) => {setLabelNumber(e.target.innerText);createLabelUpc(formatField,e.target.innerText);}}
                        renderInput={(params) => <TextField {...params} fullWidth type="text" variant="outlined" InputProps={(userRole === 'admin' )?{ readOnly: fieldDisable }:{ readOnly: true }} required label="Product" />}
                      />
                    ) : (
                      <TextField
                        required
                        fullWidth
                        margin="dense" 
                        id="model-number"
                        label="Product"
                        value={labelNumber}
                        type="text"
                        variant="outlined"
                        disabled={(userRole === 'admin' )?fieldDisable:true}
                        InputProps={(userRole === 'admin' )?{ readOnly: fieldDisable }:{ readOnly: true }}
                        InputLabelProps={{ required: false,shrink: true }}
                        onChange={(e) => {setLabelNumber(e.target.value)}}
                      />
                    )
                  }
                  </>
                )
              }
              
            </Grid>
            {!fieldDisable &&
            <Grid xs={12} sm={6}>
              <TextField
                required
                margin="dense"
                id="Label UPC"
                label="Label UPC"
                type="text"
                fullWidth
                variant="outlined"
                value={labelUPC}
                disabled
                InputProps={{ readOnly: true }}
                InputLabelProps={{ shrink: labelUPC ? true : false }}
                onChange={(e) => setLabelUPC(e.target.value)}
                helperText={labelUPCMessage}
              />
            </Grid>
            }
          </Grid>
          </form>
        </Box>
          </>
        ) : (
        <>
          <Box sx={{ py: 3, px: 2,bgcolor: 'background.paper' }}>
            <Typography variant="h4" component="h1">
              There are no requested jobs link to this packaging order ({params.id})
            </Typography>
          </Box>
        </>
        )}
        
        </>
    )
  }else{
    return(
    <>
      <Box sx={{ mb: 3 }}>
        <Breadcrumbs aria-label="breadcrumb">
          <LinkMui underline="hover" color="inherit" component={Link} to="/">
            Dashboard
          </LinkMui>
          <LinkMui underline="hover" color="inherit" component={Link} to="/requested-jobs">
            Requested Jobs
          </LinkMui>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} width={60} />
        </Breadcrumbs>
      </Box>
      <Box sx={{ py: 3, px: 2,bgcolor: 'background.paper',display: 'flex',alignItems: 'center',justifyContent:'center' }} >
        <CircularProgress />
      </Box>
    </>
    )
  }
}

export default SingleRequestedJobs