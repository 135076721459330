import * as React from 'react';
import { supabase } from '../supabaseClient'

export const IsUserAdmin = (props) => {
  const { user } = props.session
  
  const [role, setRole] = React.useState(false)

  React.useEffect(() => {
    getProfile()
    // eslint-disable-next-line
  }, [props.session])

  const getProfile = async () => {

    try {
      let { data, error, status } = await supabase
        .from('users')
        .select('role')
        .eq('id', user.id)
        .single()

      if (error && status !== 406) {
        throw error
      }
      if (data.role === 'admin') {
        setRole(true)
      }

    } catch (error) {
      console.warn(error.message)
    }
  };

  return role;
};