import { useState, useEffect } from 'react'
import { supabase } from '../supabaseClient'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
// import EditTank from './editTank'
import Grid from '@mui/material/Unstable_Grid2'

const Account = ({ session }) => {
  const [loading, setLoading] = useState(true)
  const [fullName, setFullName] = useState(null)
  const email = session.user.email

  useEffect(() => {
    getProfile()
    // eslint-disable-next-line
  }, [session])

  const getProfile = async () => {
    try {
      setLoading(true)
      const { user } = session

      let { data, error, status } = await supabase
        .from('users')
        .select('full_name')
        .eq('id', user.id)
        .single()

      if (error && status !== 406) {
        throw error
      }

      if (data) {
        setFullName(data.full_name)
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  const updateProfile = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      const { user } = session

      const updates = {
        id: user.id,
        email: email,
        full_name: fullName
      }

      let { error } = await supabase
        .from('users')
        .upsert(updates)

      if (error) {
        throw error
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    
    <div aria-live="polite" className="cds--col">
      <Grid container spacing={4}>
        <Grid xs={12} sm={12}>
          {loading ? (
            <CircularProgress />
          ) : (
            <form onSubmit={updateProfile}>
              <Stack spacing={3}>
                <TextField
                  id="email"
                  label="Email"
                  type="email"
                  disabled
                  InputProps={{ readOnly: true }}
                  defaultValue={email}
                />
                <TextField
                  id="username"
                  label="Name"
                  type="text"
                  defaultValue={fullName || ''}
                  onChange={(e) => setFullName(e.target.value)}
                />
                <Button disabled={loading} type="submit" variant="contained" size="large">
                  Update profile
                </Button>
              </Stack>
            </form>
          )}
        </Grid>
        {/* <Grid xs={12} sm={6}>
          <EditTank session></EditTank>
        </Grid> */}
      </Grid>
      {/* <Button variant="text" color="error" onClick={() => supabase.auth.signOut()}>
        Sign Out
      </Button> */}
    </div>
  )
}

export default Account