import { useState } from 'react'
import { supabase } from '../supabaseClient'

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import {Auth as AuthUI, ThemeSupa} from '@supabase/auth-ui-react';

export default function Auth() {
  const [openSuccess, setOpen] = useState(false); 
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [openError, setOpenError] = useState(false);
  const [errorFeedback, setError] = useState(false)

  const handleCloseSuccess = () => {
    setOpen(false);
  };
  const handleCloseError = () => {
    setOpenError(false);
  };

  const handleLogin = async (e) => {
    e.preventDefault()

    try {
      setLoading(true)
      const { error } = await supabase.auth.signInWithOtp({ email })
      if (error) throw error
      setOpen(true)
    } catch (error) {
      setError(error);
      setOpenError(true);
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
    
    <Stack spacing={3}>
      <div className="vy_auth_header">
      <Typography className="vy_auth_title" color="text.primary" variant="h4">Authentification</Typography>
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <AuthUI 
            supabaseClient={supabase}
            appearance={{theme:ThemeSupa}}
            providers={['google']}
          />
          <p className="vy_auth_description">
            Sign in via magic link with your email below
          </p>
          <form className="vy_auth_form" onSubmit={handleLogin}>
            <Stack spacing={2}>
            <TextField
              id="email"
              label="Your email"
              type="email"
              defaultValue={email}
              onChange={(e) => setEmail(e.target.value)}
            />
              <Button type="submit" variant="contained" size="large">
                Log in with magic link
              </Button>
            </Stack>
          </form>
        </>
      )}
        <Dialog
          open={openSuccess}
          onClose={handleCloseSuccess}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogTitle>
            {"Magic link sent successfully!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
            <p style={{ marginBottom: '1rem' }}>
              A link as been sent to your email. Click on the link to sign in.
            </p>
            <p>
              Please verify your spam box if you dosen't find the email.
            </p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSuccess}>Close</Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openError}
          onClose={handleCloseError}
          fullWidth={true}
          maxWidth={"sm"}
        >
          <DialogTitle>
            {"Error!"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <p>{errorFeedback.error_description}</p>
              <p>{errorFeedback.message}</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseError}>Close</Button>
          </DialogActions>
        </Dialog>
    </Stack>
    </>
  )
}