import * as React from 'react';
import { supabase } from '../supabaseClient'

import AddRequestedJobs from './AddRequestedJobs'

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
// import DialogContentText from '@mui/material/DialogContentText';
// import Chip from '@mui/material/Chip';
import { format } from 'date-fns';
import { ReadRowsSupabase } from "./ReadRowsSupabase";
import TableSkeleton from "./TableSkeleton";
import { IsUserAdmin } from './isUserAdmin';

import {useNavigate, useLocation} from 'react-router-dom';


// let headers = ['Packaging order','Requested date','Filling line','Volume','Ordered quantity','Label number','Label UPC','Cover type',''];
let headers = ['Batch number','Batch size','Requested date','Product','Volume','Ordered quantity'];
let rows = [];

const titlePage = 'Requested Jobs';

const slugify = str =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '')
    .replace(/^-+|-+$/g, '');

// function clickRow(){
//   console.log('You clicked submit.'); 
//   // return false
// }

const RequestedJobs = ({ session, alertAdd }) => {

  const isAdmin = IsUserAdmin({'session':session});

  // const fillingLineDB = ReadRowsSupabase({'session':session,'table':'fillingLine'});
  // let fillingLine = [];

  // if(fillingLineDB?.data){
  //   fillingLineDB.data.forEach((data, index) => {
  //     fillingLine[data.id] = data.name;
  //   });
  // }

  const formatDB = ReadRowsSupabase({'session':session,'table':'format'});
  let formatData = [];

  if(formatDB?.data){
    formatDB.data.forEach((data, index) => {
      formatData[data.id] = data.suffix + ' - ' + data.name;
      // formatData[data.id] = {id:data.id,name:data.name,suffix:data.suffix};
    });
  }

  const coverTypeDB = ReadRowsSupabase({'session':session,'table':'coverType'});
  let coverType = [];

  if(coverTypeDB?.data){
    coverTypeDB.data.forEach((data, index) => {
      coverType[data.id] = data.name;
    });
  }

  const batchSizeDB = ReadRowsSupabase({'session':session,'table':'batchSizes'});
  let batchSize = [];

  if(batchSizeDB?.data){
    batchSizeDB.data.forEach((data, index) => {
      batchSize[data.id] = data.name;
    });
  }
  
  const location = useLocation();

  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(true)
  const [requestedJobs, setRequestedJobs] = React.useState(null)
  const [openAlertAdd, setOpenAlertAdd] = React.useState(false)

  // if(location.state?.alertAdd) setOpenAlertAdd(true);

  // const openAlert = () => {
  //   setOpenAlertAdd(true);
  // };
  const closeAlert = () => {
    setOpenAlertAdd(false);
  };

  React.useEffect(() => {
    getRequestedJobs()
    // eslint-disable-next-line
  }, [session])

  const getRequestedJobs = async () => {
    try {
      setLoading(true)

      let { data: requested_jobs, error, status } = await supabase
        .from('requestedJobs')
        .select('*')
        .eq('isRunning',false)
        .order('requestedDate', { ascending: true })
        .eq('deleted',false)

      setError(error)

      if (error && status !== 406) {
        throw error
      }
      if (requested_jobs) {
        setRequestedJobs(requested_jobs)
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setLoading(false)
      if(location.state?.alertAdd) setOpenAlertAdd(true);
      navigate(location.pathname, { replace: true });
    }
  }

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const navigate = useNavigate();
  const startJobRequest=(row,headers)=>{
    navigate('/requested-job/'+row.id);
  }
  if (loading) {
    return (
      <div className="vy_requestedjobs is-loading">
        <Typography gutterBottom variant="h4">
          { titlePage }
        </Typography>
        <div className="vy_requestedjobs_form">
					<Paper sx={{ width: '100%', overflow: 'hidden' }}>
						<TableSkeleton numberCell="5" rowsPerPage={rowsPerPage} />
						<TablePagination
							rowsPerPageOptions={[10, 25, 100]}
							component="div"
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Paper>
				</div>
    	</div>
    );
  }

  if (error) {
    return(
      <div className="vy_requestedjobs is-error">
        <Typography gutterBottom variant="h4">
          { titlePage }
        </Typography>
        <div className="vy_requestedjobs_form">
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Failed to load
          </Alert>
        </div>
      </div>
    )
  }

  if (!error && !loading){
  return (
<Stack className="vy_requestedjobs" spacing={3}>
	<Box>
		<Grid container spacing={2} justifyContent="space-between" alignItems="center">
			<Grid>
				<Typography variant="h4">
					{ titlePage }
				</Typography>
			</Grid>
			<Grid>
				{isAdmin &&
					<AddRequestedJobs session={session} />
				}
			</Grid>
		</Grid>
	</Box>
  
  <div className="vy_requestedjobs_form">
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <TableCell
                  key={slugify(header)}
                  align="left"
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {requestedJobs
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row,row_key) => {
                let columns = [];
                // Object.keys(row).forEach((row_column,row_column_key) => {
                //   columns.push(row[row_column]);
                // })
                columns.push(row.packagingOrder);
                columns.push(batchSize[row.batchSize]);
                if(row.requestedDate) columns.push(format(new Date(row.requestedDate.replace(/-/g, '/')), 'MMM. dd, yyyy'));
                else columns.push("")
                // columns.push(fillingLine[row.fillingLine]);
                columns.push(row.labelNumber);
                columns.push(formatData[row.format]);
                columns.push(row.orderedQuantity);
                // columns.push(row['Float required']);
                // columns.push(row.labelUPC);
                // columns.push(coverType[row.coverType]);

                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row_key} onClick={() => startJobRequest(row,headers)}>
                    {columns.map((column,column_key) => {
                      return (
                        <TableCell key={column_key} align="left">
                          {column === false ? 'False' : ''}
                          {column === true ? 'True' : column}
                        </TableCell>
                      );
                    })}
                    {/* <TableCell key="states" align="left">
                      {row.floatRequired  ? (
                        <Chip label="Float required" />
                      ) : (
                        ''
                      )}
                    </TableCell> */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={requestedJobs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>

  </div>
  
  <Snackbar open={openAlertAdd} autoHideDuration={6000} onClose={closeAlert}>
    <Alert onClose={closeAlert} severity="success" sx={{ width: '100%' }}>
      New Requested Job added
    </Alert>
  </Snackbar>
 </Stack>
  )
}
}

export default RequestedJobs