import * as React from 'react';
import { supabase } from '../supabaseClient'

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom'


const AddNotice = ({session }) => {
  const navigate = useNavigate()

  const [noticeMessage, setNoticeMessage] = React.useState(null)
  const [errorField, setErrorField] = React.useState(false);

  const [alertError, setAlertError] = React.useState(false)

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const addNewItem = async (e) => {
    e.preventDefault()
    try {
      const add = {
        message : noticeMessage,
      }

      const { data, error } = await supabase
      .from('notices')
      .insert(add)
      .select("*")

      if(data){
        setOpen(false);
        navigate(0);
      }

      if (error) {
        throw error
      } 

    } catch (error) {
      setAlertError(error);
    } 
  }


  return (
    <>
      <Button size="small" variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickOpen}>
        Add new notice
      </Button>
      <Dialog 
        fullWidth={true}
        maxWidth={"sm"}
        open={open} 
        onClose={handleClose}
      >
        <DialogTitle>New Notice</DialogTitle>
        <DialogContent>
          <form id="formAddNotice" onSubmit={addNewItem}>
          <Stack spacing={3}>
            {alertError &&
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <div>{alertError.details}</div>
                <div>{alertError.message}</div>
              </Alert>
            }
            <TextField
              multiline
              rows={4}
              autoFocus
              required
              error={noticeMessage}
              margin="dense"
              id="new-notice"
              label="Message notice"
              fullWidth
              variant="outlined"
              onChange={(e) => {setNoticeMessage(e.target.value) }}
            />
          </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button form='formAddNotice' color="success" variant="contained" type="submit">Add</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default AddNotice