import * as React from 'react';
import { supabase } from './supabaseClient'

import DownloadIcon from '@mui/icons-material/Download';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';

export const getStatusColor = (e) => {
  if(e === 0) return "error"
  if(e === 1) return "warning"
  if(e === 2) return "warning"
  if(e === 3) return "primary"
  if(e === 4) return "success"
};

export const getStatusBtnText = (e) => {
  if(e === 0) return ""
  if(e === 1) return "Validate UPC"
  if(e === 2) return "Validate this job"
  if(e === 3) return "Terminate job"
  if(e === 4) return ""
};

export const getImageBucket = (image, alt) => {
  const supported_format = ["jpg", "jpeg", "png", "apng", "gif", "ico", "cur", "jfif", "pjpeg", "pjp"];
  if(image){
    const current_format = image.split(/[#?]/)[0].split('.').pop().trim();
    const urlImage = process.env.REACT_APP_S3_URL + image;

    if (supported_format.indexOf(current_format) > -1) {
      return (
        <img src={urlImage} alt={alt} />
      )
    } else {
      return (
        <Button download={urlImage} href={urlImage} color="primary" startIcon={<DownloadIcon />}>{image}</Button>
      )
    }
  }else{
    return (
      <Alert style={{width:'100%',boxSizing:'border-box'}} severity="warning">No image was submited</Alert>
    )
  }
};

export const GetUserRole = (props) => {
  const { user } = props.session
  const [role, setRole] = React.useState(false)

  React.useEffect(() => {
    getProfile()
    // eslint-disable-next-line
  }, [props.session])

  const getProfile = async () => {
    try {
      let { data, error, status } = await supabase
        .from('users')
        .select('role')
        .eq('id', user.id)
        .single()

      if (error && status !== 406) {
        throw error
      }

      if (data?.role) {
        setRole(data.role)
      }

    } catch (error) {
      console.warn(error.message)
    } 
  };

  return role;
};