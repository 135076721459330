import * as React from 'react';
import { supabase } from '../supabaseClient'

export const ReadRowsSupabase = (props) => {

  const [dataRows, setDataRows] = React.useState(null)

  let dataDB = false;
  let errorDB = false;
  let loadingDB = false;
  let statusDB = false;

  React.useEffect(() => {
    readRows()
    // eslint-disable-next-line
  }, [props.session])

  const readRows = async () => {

    try {
      loadingDB = true;
      if(!props.column) props.column = '*';
      if(props.equal){
        let { data, error, status } = await supabase
          .from(props.table)
          .select(props.column)
          .eq(props.equal.column,props.equal.equal)
          .eq('deleted', false)

          dataDB = data
          errorDB = error
          statusDB = status
      }else if(props.in){
        let { data, error, status } = await supabase
          .from(props.table)
          .select(props.column)
          .in(props.in.column,props.in.equal)
          .eq('deleted', false)

          dataDB = data
          errorDB = error
          statusDB = status
      }else if(props.notequal){
        let { data, error, status } = await supabase
          .from(props.table)
          .select(props.column)
          .neq(props.notequal.column,props.notequal.equal)
          .eq('deleted', false)

          dataDB = data
          errorDB = error
          statusDB = status
      }else{
        let { data, error, status } = await supabase
          .from(props.table)
          .select(props.column)
          .eq('deleted', false)

          dataDB = data
          errorDB = error
          statusDB = status
      }

      if(dataDB){
        loadingDB = false;
      }

      setDataRows({data:dataDB,error:errorDB,loading:loadingDB})

      if (errorDB && statusDB !== 406) {
        throw errorDB
      }
    } catch (errorDB) {
      console.warn(errorDB.message)
    } 
  };
  return dataRows;
};

// export default ReadRowsSupabase
